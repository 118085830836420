import React from "react";
import {Pill, Spinner} from "@catapultsports/referee-react";
import './filterChipList.sass';
import {FixtureFilter} from "../../screens/finder/subPages/gamesResults";
import {
    IconCalendar, IconCoach,
    IconCrosshair,
    IconDataDownload, IconErrorWarning,
    IconEvent, IconPeople,
    IconPerson, IconStadium,
    IconStopwatch1,
    IconTrophy, IconWhistle
} from "@catapultsports/referee-react/icons";
import {FilterChipIcon} from "../../enums/FilterChipIcon";

interface FilterChipListProps {
    data?: FilterChip[] | undefined
    filterData?: FixtureFilter[] | undefined,
    onRemove?: (id: string | undefined) => void
}

export interface FilterChip {
    id: string | undefined,
    label: string | undefined,
    icon: FilterChipIcon,
    isEventFilter: boolean
    removeCallback?: (filterId: string | undefined, fixtureFilters: FixtureFilter[] | undefined, filterChips: FilterChip[] | undefined) => void,
}

export const FilterChipList: React.FC<FilterChipListProps> = (props) => {
    const { data, filterData, onRemove } = props;

    const filterChipIconToSVG = (icon: FilterChipIcon) => {
        switch (icon) {
            case FilterChipIcon.Trophy:
                return <IconTrophy/>;
            case FilterChipIcon.Event:
                return <IconEvent/>;
            case FilterChipIcon.Stopwatch:
                return <IconStopwatch1/>;
            case FilterChipIcon.Person:
                return <IconPerson/>;
            case FilterChipIcon.Crosshair:
                return <IconCrosshair/>;
            case FilterChipIcon.Download:
                return <IconDataDownload/>;
            case FilterChipIcon.People:
                return <IconPeople/>;
            case FilterChipIcon.Stadium:
                return <IconStadium/>;
            case FilterChipIcon.Calendar:
                return <IconCalendar/>;
            case FilterChipIcon.Coach:
                return <IconCoach/>;
            case FilterChipIcon.Referee:
                return <IconWhistle/>;
            default:
                return <IconErrorWarning/>;
        }
    }

    return (
        <section data-testid={'filter-chip'}>
            {(data && data.length > 0) && data.map((chip, index) => (
                <Pill
                    testId={`filter-pill-${index}`}
                    value={chip.label ?? ''}
                    key={chip.id}
                    variant={'clearable'}
                    disabled={chip.label === undefined}
                    onClose={() => {
                        onRemove && onRemove(chip.id)
                        chip.removeCallback && chip.removeCallback(chip.id, filterData, data)
                    }}
                    // @ts-ignore
                    startIcon={chip.label ? filterChipIconToSVG(chip.icon) : <Spinner/>}
                    shape={"square"}
                />
            ))}
        </section>
    )
};
