import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Spinner,
    Tabs,
    Typography

} from "@catapultsports/referee-react";
import {VideoPlayer} from "../videoPlayer/videoPlayer";
import {FINDER, GENERAL} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import './gameViewer.sass';
import useFixtureContent from "../../api/MatchTracker/fixtureContent/useFixtureContent";
import {IconCancelSmall} from "@catapultsports/referee-react/icons";
import {useMixPanel} from "../../providers/Mixpanel";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {CalculateSyncPoint} from "../../utils/timeUtils";
import {GameEvent, GameViewerEventsTable} from "./gameViewerEventsTable";
import {GameResult} from "../../__types__/globalTypes";
import {VideoAngleSelector} from "../videoAngleSelector/videoAngleSelector";
import {ContentFile} from "../../api/MatchTracker/__types__/Common";
import {fileObjectToReturn} from "../../utils/objectUtils";
import {FileMedia} from "../../api/MatchTracker/fixtures/__types__/Fixtures";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";

interface GameViewerProps {
    result?: GameResult,
    onClose?: () => void,
}

let currentTimestamp = 0;

export const GameViewer: React.FC<GameViewerProps> = (props) => {
    const {result, onClose} = props;
    const mixpanel = useMixPanel();
    const {data, loading, error} = useFixtureContent(result?.id);

    const selectedVideoAngle = useSessionStore(state => state.selectedFinderVideoAngle, shallow);
    const videoList = useMemo(
        () => {
            let videoFiles: ContentFile[] = []

            if ((data?.fixture?.collectedFiles || data?.fixture?.delivery?.deliveredFiles)) {
                data?.fixture?.collectedFiles
                    .filter(file => file.videoAsset != null || file.fileDetails.fileType.toLowerCase().startsWith('video'))
                    .forEach(file => {
                        videoFiles.push(fileObjectToReturn(file))
                    })
                // append our delivered video files
                data?.fixture?.delivery?.deliveredFiles
                    .filter(file => file.fileDetails.fileType.toLowerCase().startsWith('video'))
                    .forEach(file => {
                        videoFiles.push(fileObjectToReturn(file))
                    })
            }
            return videoFiles
        },
        [data]
    )

    const trackingList = useMemo(
        () => {
            let files = [];
            const ingested = data?.fixture?.ingestedFiles?.map(x => ({ name: x.fileDetails.friendlyName, contentURL: x.fileDetails.s3Link, downloadable: !["", null, undefined].includes(x.fileDetails.s3Link)} as ContentFile));
            if (ingested && ingested.length > 0)
                files.push(...ingested);
            const collected = data?.fixture?.collectedFiles.filter(file => file.videoAsset === null).map(x => ({ name: x.fileDetails.friendlyName, contentURL: x.fileDetails.s3Link, downloadable: !["", null, undefined].includes(x.fileDetails.s3Link)} as ContentFile))
            if (collected && collected.length > 0)
                files.push(...collected);
            return files;
        },
        [data]
    )

    const matchTrackerList = useMemo(
        () => {
            if (data?.fixture?.delivery?.deliveredFiles) {
                return data?.fixture?.delivery?.deliveredFiles
                    .filter(file => !file.fileDetails.fileType.toLowerCase().startsWith('video'))
                    .map(file => ({
                        name: file.fileDetails.friendlyName,
                        contentURL: file.fileDetails.s3Link,
                        downloadable: true
                    } as ContentFile))
            }
        },
        [data]
    )

    const [activeFile, setActiveFile] = useState<ContentFile>()
    const [selectedEvent, setSelectedEvent] = useState<GameEvent>();
    const [renderEventsTab, setRenderEventsTab] = useState<boolean>(true);
    const calculateSync = (file: ContentFile | FileMedia, startTime: number | undefined) => {
        if (file) {
            return CalculateSyncPoint(
                'CollectedFile',
                file.videoAsset ? file.videoAsset.epochStartTime : 'epochStartTime' in file ? +file.epochStartTime : undefined,
                file.videoAsset ? file.videoAsset.defaultStartTime : 'defaultStartTime' in file ? +file.defaultStartTime : undefined,
                startTime
            )
        }
        return 0
    }
    const changeAngle = useCallback(
        (file?: ContentFile | FileMedia) => {
            if (file) {
                // @ts-ignore
                setSelectedEvent({
                    ...selectedEvent,
                    timestamp: calculateSync(file, selectedEvent?.epochStartTime || selectedEvent?.startTime)
                })
                setActiveFile(file as ContentFile)
            }
        },
        [selectedEvent, setSelectedEvent, setActiveFile]
    )
    const {t} = useTranslation();


    useEffect(() => {
        let newFile = undefined;
        if (videoList && videoList.length > 0) {
            newFile = (selectedVideoAngle ? videoList.find(item => item.name === selectedVideoAngle) : null) ?? videoList[0];
            localStorage.setItem('currentTimestamp', "0");
            setSelectedEvent(undefined);
        } else {
            if (trackingList && trackingList.length > 0)
                newFile = (selectedVideoAngle ? trackingList.find(item => item.name === selectedVideoAngle) : null) ?? trackingList[0];
            if (matchTrackerList && matchTrackerList.length > 0)
                newFile = (selectedVideoAngle ? matchTrackerList.find(item => item.name === selectedVideoAngle) : null) ?? matchTrackerList[0]
        }
        setActiveFile(newFile)
    }, [matchTrackerList, trackingList, videoList])


    const onListClick = useCallback((value: { downloadable: any; videoAsset: { epochStartTime: number | null | undefined; defaultStartTime: number | undefined; }; contentURL: string; })=> {
        if(!value?.downloadable) {
            if(selectedEvent) {
                // @ts-ignore
                selectedEvent.timestamp = calculateSync(value, selectedEvent?.epochStartTime || selectedEvent?.startTime);
                setSelectedEvent(selectedEvent);
            }
            setActiveFile(activeFile)
        } else if (value && value.contentURL){
            window.location.href = value.contentURL;
        }

        mixpanel.track(MixPanelEvents.FINDER_GAME_NEW_FILE_SELECTED, { file: value });
    }, [activeFile, mixpanel, selectedEvent])

    function RenderList(listData: ContentFile[] | undefined) {
        const fileList: JSX.Element[] = [];

        if (listData) {
            listData.forEach((value, index) => {
                fileList.push(
                    <div
                        key={`${value.name}-${value.contentURL}-${index}`}
                        className={activeFile?.contentURL == value.contentURL ? 'selected content-button' : 'content-button'}
                        onClick={() => onListClick(value)}
                    >
                        <Typography testId={'content-name'} variant={'button'}>{(value.downloadable ? t(GENERAL.DOWNLOAD) + " " : '') + value.name}</Typography>
                    </div>
                )
            })
        }

        return fileList;
    }

    if (loading || !trackingList || !matchTrackerList || !videoList)
        return <Spinner testId={'loading-spinner'} size={'large'}/>

    if (videoList?.length == 0 && trackingList?.length == 0 && matchTrackerList?.length == 0) {
        return <section className={'flex horizontal title-section'}>
            <Typography variant={"heading-4"}>{t(FINDER.NO_GAME_DATA)}</Typography>
            <Button size="small" variant="text" onClick={onClose}>
                <IconCancelSmall/>
            </Button>
        </section>
    }

    return (
        <section data-testid={'game-viewer'} className={'flex vertical'}>
            <section className={'title-section'}>
                <Typography numberOfLines={1} variant={"heading-4"}>{`${result?.homeTeam.name} vs ${result?.awayTeam.name}`}</Typography>
                <div>
                    <VideoAngleSelector angleList={videoList} setActiveFile={changeAngle} activeFileSource={activeFile?.contentURL}/>
                    <Button size="small" variant="text" onClick={onClose}>
                        <IconCancelSmall/>
                    </Button>
                </div>
            </section>
            {videoList && !activeFile?.downloadable && (
                <section className={'flex video-player-container'}>
                    <VideoPlayer
                        sourceUrl={[{src: activeFile?.contentURL + '#t=' + (selectedEvent?.timestamp ?? JSON.parse(localStorage.getItem('currentTimestamp')))}]}
                        updateTimeCallback={(currentTime) => {
                            if(currentTime === 0) {
                                currentTimestamp = JSON.parse(localStorage.getItem('currentTimestamp'))
                            }else{
                                localStorage.setItem('currentTimestamp', JSON.stringify(currentTime))
                            }
                            currentTimestamp = currentTime
                        }}
                        autoPlay
                    />
                </section>
            )}
            {activeFile?.downloadable && (
                <section data-testid={'download-button-container'}>
                    <Typography>No Video Available</Typography>
                </section>
            )}
            <Tabs testId={'game-viewer-tabs'}>
                {renderEventsTab && (
                <Tabs.Panel className={'game-viewer-tab'} header="Events">
                    <GameViewerEventsTable
                        result={result}
                        activeFile={activeFile}
                        setSelectedEvent={setSelectedEvent}
                        selectedEvent={selectedEvent}
                        eventsLoaded={setRenderEventsTab}
                    />
                </Tabs.Panel>
                )}
                {trackingList && trackingList.length > 0 && (
                    <Tabs.Panel className={'game-viewer-tab'} header="Eventing and Tracking">
                        <section data-testid={'content-list'}>
                            {RenderList(trackingList)}
                        </section>
                    </Tabs.Panel>
                )}
                {matchTrackerList && matchTrackerList.length > 0 && (
                    <Tabs.Panel className={'game-viewer-tab'} header="MatchTracker">
                        <section data-testid={'content-list'}>
                            {RenderList(matchTrackerList)}
                        </section>
                    </Tabs.Panel>
                )}
            </Tabs>
        </section>
    )
};
