import React, {useCallback, useEffect, useState} from "react";
import {Spinner, Typography as Text, useAlerts} from "@catapultsports/referee-react";
import {usePortalNavigation} from "../../utils/routerUtils";
import {useMixPanel} from "../../providers/Mixpanel";
import useTags from "../../api/Hub/tags/useTags";
import InfiniteScroll from "react-infinite-scroller";
import {Clip} from "../clip/clip";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import Expandable from "../expandableWrapper/expandable";
import {Comments} from "../comments/comments";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";
import {FilterItemType} from "../../api/Hub/enum/Enum";
import {MetadataGroupSideFilterMenu} from "../metadataGroupSideFilterMenu/MetadataGroupSideFilterMenu";
import {useTranslation} from "react-i18next";
import {HUB} from "../../services/i18n/i18n-constants";
import {ApiPageSize} from "../../api/enums/apiMagic";
import {useLocation} from "@tanstack/react-location";
import "./clipList.sass"
import {IMetaDataGroupEdges} from "../../api/Hub/metadataGroups/__types__/MetadataGroup";

// Use this to hide bad filters from server - metadataGroup -> name (always lowercase)
const ClipFiltersHide = [
    'competitionid', 'optagameid', 'gamelongname', 'gameshortname', 'mt_tag_number', 'mt_tag_session_time',
    'videoclipsourceid', 'matchdatalongidentifier', 'matchstarttime', 'start time', 'media type', 'associated player',
    '', 'driverabbname', 'driver name', 'driver number', 'epoch (ms)', 'focus_tag_number', 'clock', 'highlightcolourg',
    'highlightcolourr', 'lapnumber', 'lap number', 'laptime', 'lap type', 'short description', 'sector3time',
    'sector1starttime', 'sector1time', 'sector2starttime', 'sector2time', 'sector3starttime', 'turn16',
    'category', 'descriptor', 'breakdown', 'fullname', 'lap', 'length', 'location', '12 - start location y',
    'b2 dist', 'b2 dur', 'b3 dist', 'b3 dur', 'b4 dist', 'b4 dur', 'b5 dist', 'b5 dur', 'b6 dist', 'b6 dur',
    'distance (m)', 'overalltrackstate', 'performance', 'pitentrystate', 'pitexitstate', 'pitlanestate',
    'shortdescription', 'state', 'tagdescriptor', 'year', 'acceleration (m/s/s)', 'playingposition'
]
const ClipFilterHideRules = (name: string) => ClipFiltersHide.includes(name) || name.startsWith('msstate')
    || name.endsWith('crossingtime') || name.endsWith('start location t') || name.startsWith('peak band from ')

// These filters will be transformed into dropdowns locked to top of filters - always lowercase
const ClipTopLevelFilters = ['player', 'game', 'competition name', 'competition year', 'team']

export const ClipsList: React.FC<any> = () => {
    const [ selectedClipId, setSelectedClipId ] = useState<string>('');
    const [currentView, setCurrentView] = useState('list');
    const mixpanel = useMixPanel();
    const {navigate} = usePortalNavigation();
    const setCommentTag = useSessionStore(state => state.setCommentTag, shallow);
    const clearCommentTag = useSessionStore(state => state.clearCommentTag, shallow);
    const [ metadataFilters, setMetadataFilters ] = useState<string[]>([]);
    const freeTextSearch = useSessionStore(state => state.freeTextSearch, shallow);
    const { t } = useTranslation();
    const location = useLocation()
    const { showError } = useAlerts()
    const {
        state: { tags, tagsLoading, tagsHasNextPage },
        operations: { loadNextPage }
    } = useTags(
        ApiPageSize.Thirty,
        metadataFilters,
        [],
        () => showError(t(HUB.CLIP_ERROR)),
        false,
        false,
        freeTextSearch
    );

    const checkSelectedClip = useCallback(() => {
        const splitPathName = location?.current?.pathname?.split("/")
        return splitPathName[splitPathName?.length - 1]
    }, [location?.current?.pathname])

    useEffect(() => {
        let pathClipId = checkSelectedClip()

        if((selectedClipId === "")){
            if(pathClipId !== "clips") setSelectedClipId(pathClipId)
        }else{
            if(selectedClipId !== pathClipId){
                setSelectedClipId(pathClipId)
            }
        }
    }, [checkSelectedClip, selectedClipId])

    return (
        <>
            <Expandable>
                <section className='side-menu'>
                    <MetadataGroupSideFilterMenu
                        onFilterChange={setMetadataFilters}
                        itemType={FilterItemType.Tag}
                        groupFilter={(edge) => !edge.hidden && !edge.hideInFilterView && !ClipFilterHideRules(edge.name.toLowerCase())}
                        groupTransform={(edge: IMetaDataGroupEdges) => {
                            if (ClipTopLevelFilters.includes(edge.name.toLowerCase())) {
                                return {...edge, type: 1}
                            }

                            return edge
                        }}
                        isFixed
                    />
                    <section className='clips-controls'>
                        { tagsLoading
                            ? <Spinner key='spinner' size="medium"/>
                            : currentView == 'list'
                                ? <div className={'clips-scroller'} data-testid={'clips-scroller'}>
                                    {tags && tags.length > 0 ?
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={loadNextPage}
                                            hasMore={tagsHasNextPage}
                                            loader={<Spinner key='spinner' size="medium"/>}
                                            useWindow={false}>
                                            {
                                                tags && tags.map((value) =>
                                                    <Clip
                                                        onClick={() => {
                                                            if(selectedClipId != value.id) {
                                                                setSelectedClipId(value.id);
                                                                mixpanel.track(MixPanelEvents.CLIP_CLICK, { tagId: value.id, tagName: value.name });
                                                                navigate({ to: `${value.id}`, replace: true });
                                                            }
                                                        }}
                                                        key={value.id}
                                                        id={value.id}
                                                        name={value.name}
                                                        colour={value.colour}
                                                        commentCount={value.commentCount}
                                                        isActive={value. id === selectedClipId}
                                                        onArrowClick={() => {
                                                            setCommentTag(value.id, value.name);
                                                            setCurrentView('comments');
                                                        }
                                                        }/>)
                                            }
                                        </InfiniteScroll> :
                                        <Text className={"no-results-text-clips"} id={"no-results-text-clips"}>{t(HUB.NO_RESULTS)}</Text>}
                                </div>
                                : <Comments onClipBackClick={() => {
                                    clearCommentTag();
                                    setCurrentView('list');
                                }
                            } />
                        }
                    </section>
                </section>
            </Expandable>
        </>
    )
};
