import React, {MutableRefObject, useCallback, useRef, useState} from "react";
import {Button, Portal, Tooltip, Typography, usePositioner, useTheme} from "@catapultsports/referee-react";
import {IconCheck} from "@catapultsports/referee-react/icons";
import {useOutsideClick} from "../../../utils/mouseUtils";

export interface OperatorMenuProps {
    operatorUpdated: (operator: any) => void
}

export enum OperatorValue {
    EqualWithin = '[ ]',
    Equal = '=',
    EqualPartial = '~',
    EqualStartsWith = '| ~',
    EqualEndsWith = '~ |',
    NotEqual = '≠',
    LessThan = '<',
    LessThanEqualTo = '< =',
    GreaterThan = '>',
    GreaterThanEqualTo = '> =',
    Between = '∈',
}


export const OperatorMenu: React.FC<OperatorMenuProps> = (props) => {
    const {operatorUpdated} = props;
    const [options, setOptions] = useState([
        {
            value: OperatorValue.Equal,
            niceValue: 'Equals',
            id: 1,
            isSelected: true
        }, {
            value:  OperatorValue.EqualWithin,
            niceValue: 'Contains',
            id: 2,
            isSelected: false
        }, {
            value:  OperatorValue.EqualPartial,
            niceValue: 'Partial Equals',
            id: 3,
            isSelected: false
        }, {
            value:  OperatorValue.EqualStartsWith,
            niceValue: 'Starts With',
            id: 4,
            isSelected: false
        }, {
            value:  OperatorValue.EqualEndsWith,
            niceValue: 'Ends With',
            id: 5,
            isSelected: false
        }, {
            value: OperatorValue.NotEqual,
            niceValue: 'Not Equal',
            id: 6,
            isSelected: false
        }, {
            value: OperatorValue.LessThan,
            niceValue: 'Less Than',
            id: 7,
            isSelected: false
        }, {
            value: OperatorValue.LessThanEqualTo,
            niceValue: 'Less Than Equal To',
            id: 8,
            isSelected: false
        }, {
            value: OperatorValue.GreaterThan,
            niceValue: 'Greater Than',
            id: 9,
            isSelected: false
        }, {
            value: OperatorValue.GreaterThanEqualTo,
            niceValue: 'Greater Than Equal To',
            id: 10,
            isSelected: false
        }, {
            value: OperatorValue.Between,
            niceValue: 'Between',
            id: 11,
            isSelected: false
        }
    ]);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState(options[0].value);
    const theme = useTheme();

    const parent = useRef(null);
    const wrapperRef = useRef(null);
    const buttonRef = React.useRef<HTMLElement>(null);

    const {getPopperProps, getReferenceProps} = usePositioner({
        placement: 'bottom-start',
    });

    const handleOptionClick = useCallback((item) => {
        setSelectedOperator(item.value)
        const updatedOptions = options.map(option =>
            option.id === item.id ? { ...option, isSelected: true } : { ...option, isSelected: false }
        );
        setOptions(updatedOptions);
        setIsVisible(false);
        item.isSelected = true;
        operatorUpdated(item);
    }, [operatorUpdated]);

    useOutsideClick(
        wrapperRef,
        () => {
            setIsVisible(false);
        },
        parent,
    );

    return (
        <div className={'operator-area'}>
            <div ref={parent}>
                <Button
                    {...getReferenceProps({ref: buttonRef})}
                    className={'operator-button'}
                    onClick={() => setIsVisible(true)}
                    variant={'text'}
                    size={'small'}>
                    <Typography variant={'heading-5'}>{selectedOperator}</Typography>
                </Button>
            </div>
            {isVisible && (
                    <Portal {...getPopperProps()} data-theme={'light'}>
                        <div ref={wrapperRef} className={'operators-menu'}>
                            {
                                options.map(operator => <div
                                        key={operator.id}
                                        data-testid={`operator-option-${operator.id}`}
                                        onClick={() => handleOptionClick(operator)}
                                    >
                                    <Tooltip text={operator.niceValue}>
                                        <div className={'option-wrapper'}>
                                            <Typography variant={'heading-5'}>
                                                {operator.value}
                                            </Typography>
                                            {operator.isSelected && <IconCheck className={'option-selected'}/>}
                                        </div>
                                    </Tooltip>
                                    </div>
                                )
                            }
                        </div>
                    </Portal>
            )}
        </div>
    )
}