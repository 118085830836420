import React from "react";
import {Spinner, Typography as Text, useAlerts} from "@catapultsports/referee-react";
import {shallow} from "zustand/shallow";
import './collectionList.sass';
import InfiniteScroll from "react-infinite-scroller";
import {useTranslation} from "react-i18next";
import {PlaylistType, SortBy} from "../../api/Hub/enum/Enum";
import {ICollectionTile} from "../../api/Hub/collections/__types__/Collections";
import {useSessionStore} from "../../state/SessionState";
import {Collection} from "../collection/collection";
import {usePortalNavigation} from "../../utils/routerUtils";
import {useMixPanel} from "../../providers/Mixpanel";
import {useCollections} from "../../api/Hub/collections/useCollections";
import {MixPanelEvents} from "../../enums/MixPanelEvents";
import {HUB} from "../../services/i18n/i18n-constants";

interface CollectionListProps {
    playlistType: PlaylistType
    pageSize?: number
    onCollectionSelect?: (collection: ICollectionTile) => void,
    blockLoading?: boolean,
    displayLimit?: number
}

export const CollectionList: React.FC<CollectionListProps> = (props) => {
    const setStateStream = useSessionStore(state => state.setCurrentStream, shallow);
    const setCurrentStreamIndex = useSessionStore(state => state.setStreamIndex, shallow);
    const metadataFilters = useSessionStore(state => state.collections.metadataIds, shallow);
    const sortBy = useSessionStore<SortBy>(state => state.collections.sortBy, shallow);
    const freeTextSearch = useSessionStore(state => state.freeTextSearch, shallow);
    const {t} = useTranslation();
    const { showError } = useAlerts();
    const {
        state: { collections, loading, hasNextPage , error},
        operations: { loadNextPage }
    } = useCollections(
        props.pageSize,
        sortBy,
        props.playlistType,
        metadataFilters,
        freeTextSearch,
        () => showError(t(HUB.COLLECTION_UNAVAILABLE, { playlistType: props.playlistType === PlaylistType.Collection ? t(HUB.COLLECTIONS) : t(HUB.PRESENTATIONS) })));
    const mixpanel = useMixPanel();
    const {navigate} = usePortalNavigation();

    if (loading) return <Spinner />
    if (error) return <><Text variant={'heading-2'}>{props.playlistType === PlaylistType.Collection ? t(HUB.COLLECTIONS) : t(HUB.PRESENTATIONS)}</Text><p>Error: {error.message}</p></>

    return (
        <section className={'collections-wrapper'} data-testid={'collections-wrapper'}>
            { collections && collections.length > 0 ?
                <div className={'scroller'}>
                    <InfiniteScroll
                        element={'ul'}
                        threshold={100}
                        className={'collections-list'}
                        pageStart={0}
                        loadMore={props.blockLoading ? () => true : loadNextPage}
                        hasMore={!props.blockLoading && hasNextPage}
                        loader={<Spinner key='spinner' size="medium"/>}
                        useWindow={false}>
                        {
                            (props.displayLimit ? collections.slice(0, props.displayLimit) : collections).map((tile, index) => (
                                <Collection
                                    id={tile.id}
                                    key={tile.id}
                                    name={tile.name}
                                    description={tile.description}
                                    creationTimestamp={tile.creationTimestamp}
                                    modifiedTimestamp={tile.modifiedTimestamp}
                                    commentCount={tile.commentCount}
                                    thumbnail={tile.thumbnail}
                                    tags={tile.tags}
                                    assetCount={tile.assetCount}
                                    users={tile.users}
                                    metadata={tile.metadata}
                                    playlistType={props.playlistType}
                                    onClick={item => {
                                        mixpanel.track(props.playlistType === PlaylistType.Collection ? MixPanelEvents.COLLECTION_SELECT : MixPanelEvents.PRESENTATION_SELECT, { collectionId: tile.id, collectionName: tile.name })
                                        if (props.onCollectionSelect) {
                                            setStateStream(undefined);
                                            setCurrentStreamIndex(-1);
                                            props.onCollectionSelect(tile)
                                        } else {
                                            navigate({ to: item.id, replace: true });
                                        }
                                    }}
                                />
                            ))
                        }
                    </InfiniteScroll>
                </div> :
                <Text className={"no-results-text-collections"} id={"no-results-text-collections"}>{t(HUB.NO_RESULTS)}</Text>
            }
        </section>
    )
}