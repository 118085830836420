import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Checkbox, Select, SideMenu, SideMenuItem, Spinner, Typography, useAlerts} from "@catapultsports/referee-react";
import {IMetaDataGroupEdges} from "../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {FilterItemType} from "../../api/Hub/enum/Enum";
import useMetadataGroups from "../../api/Hub/metadataGroups/useMetadatagroups";
import './MetadataGroupSideFilterMenu.sass';
import {ExpandableMenuItem} from "../expandableMenuItem/expandableMenuItem";
import {useTranslation} from "react-i18next";
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {CleanupTypeName} from "../../utils/stringUtils";

interface MetadataGroupFilterProps {
    onFilterChange: (selectedFilters: string[]) => void,
    itemType?: FilterItemType
    isMock?: boolean,
    isFixed?: boolean
    groupFilter?: (edge: IMetaDataGroupEdges) => void,
    groupTransform?: (edge: IMetaDataGroupEdges) => IMetaDataGroupEdges
    pageSize?: number
}

interface DropdownOpenState {
    id: string,
    isOpen: boolean
}

interface DropdownSelectedState {
    id: string,
    selectedID: string,
}

const NO_VALUE = 'NO_VALUE';

export const MetadataGroupSideFilterMenu: React.FC<MetadataGroupFilterProps> = (props) => {
    const {onFilterChange, itemType, isMock, isFixed, groupFilter, groupTransform, pageSize} = props;
    const [ selectedFilters, setSelectedFilters ] = useState<string[]>([]);
    const [ dropdownOpenStates, setDropdownOpenStates ] = useState<DropdownOpenState[]>([]);
    const [ dropdownSelectedStates, setDropdownSelectedStates ] = useState<DropdownSelectedState[]>([]);
    const [clearAll, setClearAll] = useState(false)
    const { t } = useTranslation();
    const { showError } = useAlerts();
    const {
        state: {metadataGroups, loading},
    } = useMetadataGroups(pageSize ?? -1, itemType || FilterItemType.Playlist, isMock, groupFilter, () => showError(t(HUB.FILTERS_ERROR)));


    useEffect(() => {
        const dropdownFilters: string[] = dropdownSelectedStates.filter(x => x.selectedID != '').map(x => x.selectedID);
        const filters = [...selectedFilters, ...dropdownFilters];

        onFilterChange(filters);
    }, [selectedFilters, dropdownSelectedStates, onFilterChange]);

    const primaryGroup = useMemo(() => {
        return metadataGroups?.map(group => groupTransform ? groupTransform(group) : group).filter(x => x.type === 1);
    }, [groupTransform, metadataGroups])

    const secondaryMetaDataGroups = useMemo(() => {
        return  metadataGroups?.map(group => {
            const newGroup = groupTransform ? groupTransform(group) : group;
            return {...newGroup, name: CleanupTypeName(newGroup.name)};
        }).filter(x => x.type !== 1)
    }, [groupTransform, metadataGroups])

    const clearAllPrimarySecondaryFiltersFunction = useCallback(async () => {
        setSelectedFilters([])
        setClearAll(true)
        setDropdownSelectedStates([])
    }, [])

    const clearALLFunction = useCallback(async () => {
        await clearAllPrimarySecondaryFiltersFunction()
        setClearAll(false)

    }, [clearAllPrimarySecondaryFiltersFunction])

    const numberOfFiltersSelected = useMemo(() => {
        let numberOfSecondarySelectedFilters = selectedFilters.length
        let primarySelectedFilters = dropdownSelectedStates.filter(element => element.selectedID != "").length
        return numberOfSecondarySelectedFilters + primarySelectedFilters
    }, [dropdownSelectedStates, selectedFilters.length])

    const getMenuItem = isFixed ?
        (edge: IMetaDataGroupEdges, value: string, checkboxes: JSX.Element[]) => {
            return <ExpandableMenuItem
                testId={'metaGroupData-filter-item-' + edge.name}
                key={edge.id}
                label={edge.name}
                value={value}
                reverseButtonOrder
            >
                {checkboxes}
            </ExpandableMenuItem>;
        } :
        (edge: IMetaDataGroupEdges, value: string, checkboxes: JSX.Element[]) => {
            return <SideMenuItem
                testId={'metaGroupData-filter-item-' + edge.name}
                key={edge.id}
                label={edge.name}
                value={value}
            >
                {checkboxes}
            </SideMenuItem>;
        };


    useEffect(() => {
        primaryGroup?.forEach(edge => {
            if (dropdownSelectedStates.filter(x => x.id == edge.name).length <= 0) {
                setDropdownSelectedStates([{id: edge.name, selectedID: ''}, ...dropdownSelectedStates])
            }

            if (dropdownOpenStates.filter(x => x.id == edge.name).length <= 0) {
                setDropdownOpenStates([{id: edge.name, isOpen: false}, ...dropdownOpenStates])
            }
        }
        )
    }, [dropdownOpenStates, dropdownSelectedStates, primaryGroup])


    const selectMenuItems = useCallback((items: any) => {
        if(clearAll){
            let itemsCopy = JSON.parse(JSON.stringify(items))
            return itemsCopy.map((data) => {
                return {
                    ...data,
                    isSelected: false
                }
            })
        }
        return items
    }, [clearAll])

    const renderPrimaryMenuItems = useCallback((data: IMetaDataGroupEdges[]) => {
        let menuItems: JSX.Element[] = [];

        data?.forEach(edge => {
            const itemsToDisplay = selectMenuItems(edge.metadata)
            menuItems.push(
                <Select
                    key={edge.id}
                    testId={'metaGroupData-filter-dropdown-' + edge.name.toLowerCase()}
                    onSelectItem={x => {
                        if (x.length > 0) {
                            const dropdownList = dropdownSelectedStates.map(dropdown => {
                                if (dropdown.id == edge.name) {
                                    return {
                                        ...dropdown,
                                        selectedID:  x[0].id.toString(),
                                    };
                                } else {
                                    return dropdown;
                                }
                            });
                            setDropdownSelectedStates(dropdownList);
                        }else{
                            const dropdownList = dropdownSelectedStates.map(dropdown => {
                                if (dropdown.id == edge.name) {
                                    return {
                                        ...dropdown,
                                        selectedID:  "",
                                    };
                                } else {
                                    return dropdown;
                                }
                            });
                            setDropdownSelectedStates(dropdownList);
                        }
                    }}
                    isSearchable
                    placeholder={edge.name}
                    menuItems={itemsToDisplay}
                    onOpenClose={x => {
                        const dropdownList = dropdownOpenStates.map(dropdown => {
                            if (dropdown.id == edge.name) {
                                return {
                                    ...dropdown,
                                    isOpen: x,
                                };
                            } else {
                                return dropdown;
                            }
                        });
                        setDropdownOpenStates(dropdownList);
                    }}
                    isClearButtonVisible={true}
                    setVisible={dropdownOpenStates.filter(x => x.id == edge.name)[0] && dropdownOpenStates.filter(x => x.id == edge.name)[0].isOpen}
                    isExternalUpdated={clearAll}
                />);
        });
        return menuItems;
    }, [clearAll, dropdownOpenStates, dropdownSelectedStates, selectMenuItems])


    function RenderSecondaryMenuItems(data: IMetaDataGroupEdges[]) {
        const menuItems: JSX.Element[] = [];
        data?.forEach(edge => {
            const checkboxes: any[] = [];
            edge.metadata.forEach(metaData => {
                if(metaData.itemCount > -1) {
                    checkboxes.push(
                        <div key={metaData.id} data-testid={`secondary-checkbox-${metaData.id}-${metaData.value}-div`}>
                            <Checkbox
                                testId={`secondary-checkbox-${metaData.id}-${metaData.value}`}
                                Id={`${metaData.id}-${metaData.value}`}
                                label={metaData.value.includes(NO_VALUE) ? t(HUB.ENABLED) : metaData.value}
                                checked={selectedFilters.includes(metaData.id)} onChange={(x) => {
                                    if (selectedFilters.includes(metaData.id))
                                        setSelectedFilters(selectedFilters.filter(x => x != metaData.id));
                                    else
                                        setSelectedFilters([...selectedFilters, metaData.id]);
                            }}/>
                        </div>
                    )
                }
            })

            if (checkboxes.length > 0)
                menuItems.push(getMenuItem(edge, menuItems.length.toString(), checkboxes));
        })

        return menuItems;
    }

    return (
        isFixed ?
        <section
            data-testid={'metaGroupData-filter-fixed-menu'}>
            {numberOfFiltersSelected > 0 ? (
                <div data-testid={"filter-div"}>
                    <div data-testid={"first-div"}>
                        <Typography testId={"filter-text"} variant="label-1">{t(GENERAL.FILTERS)}</Typography>
                        <Typography testId={"number-of-filters"} variant="label-1">{numberOfFiltersSelected}</Typography>
                    </div>
                    <Typography testId={"clear-all-text"} variant="label-1" onClick={clearALLFunction}>{t(GENERAL.CLEAR_ALL_FILTERS)}</Typography>
                </div>
            ): <div className={"filter-div"}></div>}
            {
                loading ?
                    <Spinner size={"medium"} testId={"loading-spinner-fixed"}/>
                    :
                    <div className={'root'} data-testid={"filter-menu-fixed"}>
                        <section data-testid={"primary-group-section-fixed"}>
                            {(primaryGroup && primaryGroup.length > 0) && renderPrimaryMenuItems(primaryGroup)}
                        </section>
                        <section data-testid={"secondary-group-section-fixed"}>
                            {(secondaryMetaDataGroups && secondaryMetaDataGroups.length > 0) && RenderSecondaryMenuItems(secondaryMetaDataGroups)}
                        </section>
                    </div>
            }
        </section> :
            loading?
                <SideMenu testId={'metaGroupData-filter-menu'} onExpandCollapse={(x) => {
                    if(!x) {
                        const dropdownList = dropdownOpenStates.map(dropdown => {
                            return {
                                ...dropdown,
                                isOpen: false,
                            };
                        });
                        setDropdownOpenStates(dropdownList);
                    }
                }} >
                    {loading && (
                        <section>
                            <Spinner variant={"muted"} testId={"loading-spinner"}/>
                        </section>
                    )}
                    {!loading && !((primaryGroup && primaryGroup.length > 0) || (secondaryMetaDataGroups && secondaryMetaDataGroups.length > 0)) && (
                        <section>
                            <Typography>{HUB.METADATA_GROUP_NOT_FOUND}</Typography>
                        </section>
                    )}
                    {!loading && ((primaryGroup && primaryGroup.length > 0) || (secondaryMetaDataGroups && secondaryMetaDataGroups.length > 0)) && (
                        <>
                            <section data-testid={"primary-group-section"}>
                                {((primaryGroup && primaryGroup.length > 0)) && renderPrimaryMenuItems(primaryGroup)}
                            </section>
                            <section data-testid={"secondary-group-section"}>
                                {(secondaryMetaDataGroups && secondaryMetaDataGroups.length > 0) && RenderSecondaryMenuItems(secondaryMetaDataGroups)}
                            </section>
                        </>
                    )}
                </SideMenu>
            :
                null
    )
}
