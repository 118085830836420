import React, {useCallback} from "react";
import {COLLECTIONS, GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {Typography, useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import "./collection.sass"
import {useMixPanel} from "../../providers/Mixpanel";
import {HubDialogComponent} from "../hubDialog/hubDialog";
import {ApolloError} from "@apollo/client";
import useDeletePlaylist from "../../api/Hub/playlist/deletePlaylist/useDeletePlaylist";
interface CollectionDeleteProps {
    collectionId: string;
    displayDeleteModal: boolean;
    setDisplayDeleteModal: (displayDeleteModal: boolean) => void;
    setDisplaySelectOptions: (displaySelectOptions: boolean) => void;
}

export let deleteDialogId = "delete-collection-list-collection-modal"


export const CollectionDelete: React.FC<CollectionDeleteProps> = (props) => {
    const {
        collectionId,
        displayDeleteModal,
        setDisplayDeleteModal,
        setDisplaySelectOptions
    } = props;

    const { t } = useTranslation();
    const { showError, showInfo } = useAlerts();
    const mixpanel = useMixPanel();

    const errorAlert = (error: ApolloError, errorMessage: string) => {
        showError(t(HUB.DELETE_PLAYLIST_ERROR));
    };

    const onSuccess = useCallback(() => {
        mixpanel.track(`Deleted Playlist`, { collectionID: collectionId });
        let text = `${t(COLLECTIONS.COLLECTION_DELETED)}`
        showInfo(text);
    }, [collectionId, mixpanel, showInfo, t])

    const {deletePlaylists} = useDeletePlaylist(errorAlert, onSuccess);

    let deleteCollectionFunction = useCallback(() => {
        setDisplayDeleteModal(false)
        setDisplaySelectOptions(false)
        let updateObject = [{id: collectionId}]
        deletePlaylists(updateObject);

    }, [setDisplayDeleteModal, setDisplaySelectOptions, collectionId, deletePlaylists])

    return (
        <HubDialogComponent
            dialogTheme={"dark"}
            dialogSize={'small'}
            dialogHeader={t(COLLECTIONS.COLLECTION_DELETE_MODAL_HEADER).toString()}
            isModalOpen={displayDeleteModal}
            setIsModalOpen={setDisplayDeleteModal}
            modalContent={
                <div
                    data-testid={`${deleteDialogId}-content-div`}
                    id={`${deleteDialogId}-content-div`}
                >
                    <Typography
                        testId={`${deleteDialogId}-content-sub-header`}
                        id={`${deleteDialogId}-content-sub-header`}
                        variant={"body-1"}>{t(COLLECTIONS.COLLECTION_DELETE_MODAL_TEXT)}
                    </Typography>
                </div>
            }
            displayDialogFooterButtons={true}
            hideCloseButton={true}
            footerPrimaryButtonText={t(GENERAL.DELETE).toString()}
            footerPrimaryButtonAction={deleteCollectionFunction}
            footerPrimaryButtonDisabled={false}
            dialogId={deleteDialogId}
            allowDialogClosure={false}
        />
    )
}