import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import {FINDER, GENERAL, HUB, NAV} from "../../../services/i18n/i18n-constants";
import {
    Checkbox,
    DateRangePicker,
    GroupedSelectOptions,
    Select,
    SelectOption,
    Typography,
    useAlerts,
    useDebounce
} from "@catapultsports/referee-react";
import {useMixPanel} from "../../../providers/Mixpanel";
import {compressDateRangeSeconds, useDatePresets} from "../../../utils/dateUtils";
import useCompetitions from "../../../api/MatchTracker/competitions/useCompetitions";
import {FilterChip, FilterChipList} from "../../../components/filterChipList/filterChipList";
import {
    IconCoach,
    IconCrosshair,
    IconDataDownload,
    IconEvent,
    IconStadium,
    IconStopwatch1,
    IconWhistle
} from "@catapultsports/referee-react/icons";
import {FixtureFilter} from "./gamesResults";
import useTeams from "../../../api/MatchTracker/teams/useTeams";
import useVenues from "../../../api/MatchTracker/venues/useVenues";
import useEventTypes from "../../../api/MatchTracker/eventTypes/useEventTypes";
import {CleanupTypeName} from "../../../utils/stringUtils";
import useQualifierTypes from "../../../api/MatchTracker/qualifierTypes/useQualifierTypes";
import usePhaseTypes from "../../../api/MatchTracker/phaseTypes/usePhaseTypes";
import useProviders from "../../../api/MatchTracker/providers/useProviders";
import usePlayers from "../../../api/MatchTracker/players/usePlayers";
import {FixtureFilterType, ResultType} from "../../../api/MatchTracker/enum/Common";
import {FilterTypeFromGroupName, FixtureFilterTypeToGroupData} from "../../../api/MatchTracker/util/EnumUtil";
import {defineQueryFilters, GameEvent, SearchLocationTypes, usePortalNavigation} from "../../../utils/routerUtils";
import {useLocation, useSearch} from "@tanstack/react-location";
import {arraysEqual} from "../../../utils/arrayUtils";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import useOfficialMatchInvolvements
    from "../../../api/MatchTracker/officialMatchInvolvements/useOfficialMatchInvolvements";
import {ISeason} from "../../../api/MatchTracker/__types__/Common";
import useSeasons from "../../../api/MatchTracker/seasons/useSeasons";
import {FilterChipIcon} from "../../../enums/FilterChipIcon";
import {useUpdateFilter} from "../../../state/stateUtil";
import {SEARCH_DEBOUNCE_DEFAULT} from "../../../api/enums/clientMagic";

export const SearchControls: React.FC<any> = () => {
    //imported hooks
    const {navigate} = usePortalNavigation();
    const {t} = useTranslation();
    const mixpanel = useMixPanel();
    const {filters} = useSearch<SearchLocationTypes>();
    const {showError} = useAlerts();
    const datePresets = useDatePresets();

    const location = useLocation()
    const updateFilter = useUpdateFilter({location: location, navigate: navigate});
    const debouncedUpdateFilter = useDebounce((value: any) => {
        updateFilter(value);
    }, SEARCH_DEBOUNCE_DEFAULT);
    const [isExternalUpdated, setIsExternalUpdated] = useState<boolean>(false);
    const [resultTypeUpdated, setResultTypeUpdated] = useState<boolean>(false);

    const resultsType = useMemo(() => {
        return filters?.resultsType || ResultType.Matches
    }, [filters?.resultsType]);
    const gameEvents = useMemo(() => {
        return filters?.gameEvents || []
    }, [filters?.gameEvents]);

    const filterIds = useMemo(() => {
        return filters?.filterIds || []
    }, [filters?.filterIds]);
    const {state: {seasons, seasonsLoading, seasonsPageSize}} =
        useSeasons(undefined, undefined, undefined, undefined, () => showError(t(HUB.SEASONS_ERROR)));

    const [competitionId, videoChecked, eventsChecked, perfectMatchChecked, dateRange, seasonIds, teamIds, playerIds, includeFutureFixtures] = defineQueryFilters(filters, datePresets)

    const resultsOptions = useMemo(() => {
        setResultTypeUpdated(true);
        setIsExternalUpdated(true);
        if (resultsType == ResultType.Matches) {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: true},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: false}
            ]
        } else {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: false},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: true}
            ]
        }
    }, [resultsType]);

    const fixtureFilters = useSessionStore<FixtureFilter[]>(state => state.search.fixtureFilters, shallow);
    const filterChips = useSessionStore<FilterChip[]>(state => state.search.filterChips, shallow);
    const setFilterValues = useSessionStore<(fixtureFilters: FixtureFilter[], filterChips: FilterChip[]) => void>(state => state.setSearchItems, shallow);
    const filterState = useSessionStore<string[]>(state => state.search.filterIds, shallow);
    const setFilterState = useSessionStore<(filterIds: string[]) => void>(state => state.setFilterIds, shallow);

    // TODO: this should be paged once infinite scroll is available in select component
    const {state: {competitions, competitionsLoading, competitionsError}} =
        useCompetitions(undefined, undefined, () => showError(t(HUB.COMPETITIONS_ERROR)));

    const {state: {teams, teamsLoading}} = useTeams(
        competitionId ? [competitionId] : [],
        seasonIds ? seasonIds : [],
        undefined,
        undefined,
        () => showError(t(HUB.TEAMS_ERROR)));

    const {state: {players, playersLoading}} = usePlayers(
        teamIds || [],
        undefined,
        undefined,
        () => showError(t(HUB.PLAYERS_ERROR)));

    const {state: {venues, venuesLoading}} =
        useVenues(undefined, undefined, () => showError(t(HUB.VENUES_ERROR)));

    const {state: {providers, providersLoading}} =
        useProviders(undefined, undefined, () => showError(t(HUB.PROVIDERS_ERROR)));

    const coachesState = useOfficialMatchInvolvements(true, true,
        teamIds || [],);

    const refereesState = useOfficialMatchInvolvements(false);

    const {state: {eventTypes, eventTypesLoading, eventTypesError}} = useEventTypes();
    const {state: {qualifierTypes, qualifierTypesLoading}} =
        useQualifierTypes(undefined, undefined, () => showError(t(HUB.QUALIFIER_TYPES_ERROR)));
    const {state: {phaseTypes, phaseTypesLoading}} =
        usePhaseTypes(undefined, undefined, () => showError(t(HUB.PHASE_TYPE_ERROR)));

    useEffect(() => {
        if (!arraysEqual(filterIds, filterState))
            setFilterState(filterIds);
    }, [filterIds, filterState, setFilterState]);

    let convertCurrentPreviousSeasonValues = useCallback((item: ISeason) => {
        let valueToReturn = item.endYear.toString()

        let start = item.startYear
        let end = item.endYear
        if ((start !== null && end !== null))
            if (start !== end) {
                valueToReturn = `${start}/${end}`
            }
        if (item.isCurrentSeason) {
            valueToReturn = t(FINDER.CURRENT_SEASON)
        }
        if (item.isPreviousSeason) {
            valueToReturn = t(FINDER.PREVIOUS_SEASON)
        }
        return valueToReturn
    }, [t])

    const seasonOptions = useMemo(() => {
        if (seasons && seasons.length > 0) {
            const options = [
                ...seasons.map((item) => ({
                    id: item.base.id,
                    value: convertCurrentPreviousSeasonValues(item),
                    startYear: item.startYear.toString(),
                    endYear: item.endYear.toString(),
                    isCurrentSeason: item.isCurrentSeason,
                    isPreviousSeason: item.isPreviousSeason
                }))
            ];
            const seasonIds = filters?.seasonIds
            return options.map((option) => seasonIds?.includes(option.id) ? {...option, isSelected: true} : option);
        }

        return [];
    }, [seasons, filters?.seasonIds, convertCurrentPreviousSeasonValues]);

    const teamOptions = useMemo(() => {
        if (teams && teams.length > 0) {
            const options = [
                ...teams.map((item) => ({id: item.base.id, value: item.name}))
            ];
            const teamIds = filters?.teamIds
            return options.map((option) => teamIds?.includes(option.id) ? {...option, isSelected: true} : option);
        }

        return [];
    }, [teams, filters?.teamIds]);

    const playerOptions = useMemo(() => {
        if (!playersLoading) {
            if (players && players.length > 0) {
                const options = [
                    ...players.map((item) => ({id: item.base.id, value: item.firstName + ' ' + item.lastName}))
                ];
                const playerIds = filters?.playerIds
                const validPlayerIds = playerIds?.filter(x => options.some(o => o.id !== x));
                updateFilter({playerIds: validPlayerIds ?? []});
                return options.map((option) => playerIds?.includes(option.id) ? {...option, isSelected: true} : option);
            }

            updateFilter({playerIds: []});
        }
        return [];
    }, [players, filters?.playerIds, filters?.teamIds]);

    const competitionOptions = useMemo(() => {
        if (competitions && competitions.length > 0) {
            const options = [
                {id: '', value: t(GENERAL.ANY)},
                ...competitions.map((item) => ({id: item.base.id, value: item.name}))
            ];
            return options.map((option) => option.id === competitionId ? {...option, isSelected: true} : option);
        }

        return [];
    }, [competitions, competitionId]);

    const isLoading = useMemo(
        () => playersLoading || teamsLoading || venuesLoading || providersLoading || phaseTypesLoading || competitionsLoading || eventTypesLoading || qualifierTypesLoading || phaseTypesLoading || coachesState.state.officialMatchInvolvementsLoading || refereesState.state.officialMatchInvolvementsLoading || seasonsLoading,
        [playersLoading, teamsLoading, venuesLoading, providersLoading, phaseTypesLoading, competitionsLoading, eventTypesLoading, qualifierTypesLoading, coachesState.state.officialMatchInvolvementsLoading, refereesState.state.officialMatchInvolvementsLoading, seasonsLoading]
    );

    const filterGroups = useMemo(() => {
        if (!(isLoading)) {
            const groups = [];
            if (eventTypes && eventTypes.length > 0) {
                groups.push({
                    groupName: t(FINDER.EVENT),
                    startIcon: <IconEvent/>,
                    menuItems: eventTypes.map(item => ({
                        id: item.base.id,
                        value: CleanupTypeName(item.name),
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.EventTypeIDs && f.id === item.base.id)
                    }))
                } as GroupedSelectOptions)
            }
            if (qualifierTypes && qualifierTypes.length > 0) {
                groups.push({
                    groupName: t(FINDER.QUALIFIER),
                    startIcon: <IconCrosshair/>,
                    menuItems: qualifierTypes.map(item => ({
                        id: item.base.id,
                        value: CleanupTypeName(item.name),
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.QualifierTypeIDs && f.id === item.base.id)
                    }))
                } as GroupedSelectOptions)
            }
            if (phaseTypes && phaseTypes.length > 0) {
                groups.push({
                    groupName: t(FINDER.PERIOD),
                    startIcon: <IconStopwatch1/>,
                    menuItems: phaseTypes.map(item => ({
                        id: item.base.id,
                        value: CleanupTypeName(item.name),
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.PhaseTypeIDs && f.id === item.base.id)
                    }))
                } as GroupedSelectOptions)
            }
            if (venues && venues.length > 0) {
                groups.push({
                    groupName: t(FINDER.VENUE),
                    startIcon: <IconStadium/>,
                    menuItems: venues.map(item => ({
                        id: item.base.id,
                        value: item.name,
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.VenueIDs && f.id === item.base.id)
                    }))
                } as GroupedSelectOptions)
            }
            if (providers && providers.length > 0) {
                groups.push({
                    groupName: t(FINDER.PROVIDER),
                    startIcon: <IconDataDownload/>,
                    menuItems: providers.map(item => ({
                        id: item.base.id,
                        value: CleanupTypeName(item.name),
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.ProviderIDs && f.id === item.base.id)
                    }))
                } as GroupedSelectOptions)
            }

            const coachMatchInvolvements = coachesState.state.officialMatchInvolvements;
            if (coachMatchInvolvements && coachMatchInvolvements.length > 0) {
                groups.push({
                    groupName: t(FINDER.COACH),
                    startIcon: <IconCoach/>,
                    menuItems: coachMatchInvolvements.map(item => ({
                        id: item.official.base.id + '&' + item.officialType.base.id,
                        value: item.official.firstName + ' ' + item.official.lastName,
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.CoachIDs && f.id === item.official.base.id + '&' + item.officialType.base.id)
                    }))
                } as GroupedSelectOptions)
            }

            const refereeMatchInvolvements = refereesState.state.officialMatchInvolvements;
            if (refereeMatchInvolvements && refereeMatchInvolvements.length > 0) {
                groups.push({
                    groupName: t(FINDER.REFEREE),
                    startIcon: <IconWhistle/>,
                    menuItems: refereeMatchInvolvements.map(item => ({
                        id: item.official.base.id + '&' + item.officialType.base.id,
                        value: item.official.firstName + ' ' + item.official.lastName + ': ' + item.officialType.shortName,
                        isSelected: fixtureFilters.some(f => f.filterType === FixtureFilterType.RefereeIDs && f.id === item.official.base.id + '&' + item.officialType.base.id)
                    }))
                } as GroupedSelectOptions)
            }

            return groups;
        }

        return []
    }, [isLoading, fixtureFilters, filters?.competitionId, filters?.seasonIds, teamIds]);

    const needNewFilters = (currentIds: string[], gameEvents: GameEvent[], currentFilters: FixtureFilter[], resultsType: ResultType): boolean => {

        const fixturesWithoutGames = fixtureFilters.filter(x => x.filterType != FixtureFilterType.FixtureIDs);
        if (filterGroups && filterGroups.length > 0 && fixturesWithoutGames.length > 0 && fixturesWithoutGames.every(filter => filter.name === undefined)) {
            return true;
        }

        //sort them in case the order is not guaranteed.
        let uniqueSortedByCurrent = [];

        if (resultsType === ResultType.Matches) {
            uniqueSortedByCurrent = currentIds.filter(x => !gameEvents.find(y => y.id == x));
        } else {
            const sortedCurrentIDs = [...currentIds, ...gameEvents.map(x => x.id ?? '')].sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            uniqueSortedByCurrent = sortedCurrentIDs.filter((value, index) => sortedCurrentIDs.indexOf(value) === index);
        }
        const currentFilterIds = currentFilters.map(x => x.id ?? '').sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));

        return uniqueSortedByCurrent.length != currentFilterIds.length || !arraysEqual(uniqueSortedByCurrent, currentFilterIds);
    }

    useEffect(() => {
            const generateFiltersAndClips = (filterIds: string[], gameEvents: GameEvent[], resultsType: ResultType): {
                filters: FixtureFilter[],
                chips: FilterChip[]
            } => {
                let filters: FixtureFilter[] = [];
                let chips: FilterChip[] = [];

                if (resultsType === ResultType.Events) {
                    filters = gameEvents.map(gameEvent => {
                        return {
                            filterType: FixtureFilterType.FixtureIDs,
                            id: gameEvent.id,
                            name: gameEvent.name
                        } as FixtureFilter;
                    });
                    chips = gameEvents.map(gameEvent => {
                        return {
                            id: gameEvent.id,
                            icon: FilterChipIcon.Crosshair,
                            label: gameEvent.name,
                            isEventFilter: true
                        } as FilterChip;
                    })
                }
                const usedIds = filters.map(filter => filter.id).filter(id => id != undefined);
                const newFilters = filterGroups && filterGroups.length > 0 && filterIds.length > 0 ?
                    filterIds.filter(id => !usedIds.includes(id))
                        .map(id => generateFixtureFilterFromIds(id, filterGroups))
                        .filter(filter => filter != null) as FixtureFilter[] :
                    filterIds.filter(id => !usedIds.includes(id))
                        .map(id => {
                            return {
                                filterType: FixtureFilterType.Unknown,
                                id: id,
                                name: undefined
                            };
                        });
                return {
                    filters: [...filters, ...newFilters],
                    chips: [...chips, ...newFilters.length > 0 ?
                        newFilters.map(filter => generateFilterChipFromFixtureFilter(filter)).filter(chip => chip != null) as FilterChip[] :
                        []]
                };
            }

            if (needNewFilters(filterState, gameEvents, fixtureFilters, resultsType)) {
                const newValues = generateFiltersAndClips(filterState, gameEvents, resultsType);

                if (resultsType == ResultType.Matches) {
                    const matchFilters = newValues.filters.filter(x => x.filterType !== FixtureFilterType.FixtureIDs);
                    const matchChips = newValues.chips.filter(x => !x.isEventFilter);
                    setFilterValues(matchFilters, matchChips);
                    const matchIds = new Set([...matchFilters.map(filter => filter.id), ...matchChips.map(chip => chip.id)]);
                    // @ts-ignore
                    debouncedUpdateFilter({filterIds: [...matchIds], gameEvents: []});
                    setFilterValues(newValues.filters, newValues.chips);
                    return;
                }

                setFilterValues(newValues.filters, newValues.chips);
                return;
            }
            if (filterState.length == 0 && gameEvents.length == 0 && fixtureFilters.length > 0)
                setFilterValues([], []);
        },
        [filterGroups, filterState, gameEvents, resultsType]
    );

    const onFilterRemove = useCallback(
        (filterId: string | undefined) => {
            if (filterId) {
                setIsExternalUpdated(true);
                debouncedUpdateFilter({
                    filterIds: filterState.filter(id => id != filterId),
                    gameEvents: gameEvents.filter(x => x.id != filterId)
                });
            }
        },
        [filterState]
    );

    const onFilterSelect = useCallback(
        (items: SelectOption[]) => {

            //check if a teamID was removed.
            if (items.length == 0 && fixtureFilters.some(x => x.filterType === FixtureFilterType.TeamIDs)) {
                setIsExternalUpdated(true);
            } else {
                setIsExternalUpdated(false);
            }

            if (items.length > 0 || fixtureFilters.some(x => x.filterType === FixtureFilterType.FixtureIDs)) {
                const activeItemFilters = items.map(item => generateFixtureFilterFromSelect(item, filterGroups));

                //has teams changed.
                const currentTeamFilters = teamIds;
                const newTeamFilters = activeItemFilters.filter(x => x?.filterType === FixtureFilterType.TeamIDs);

                if (!arraysEqual(currentTeamFilters, newTeamFilters)) {
                    setIsExternalUpdated(true);
                }


                const activeFilters = [...activeItemFilters, ...fixtureFilters.filter(x => x.filterType === FixtureFilterType.FixtureIDs)]
                if (!arraysEqual(fixtureFilters, activeFilters)) {
                    const ids = (activeFilters.filter(item => item != null) as FixtureFilter[]).map((item) => item.id as string);
                    if (!arraysEqual(filterState, ids))
                        debouncedUpdateFilter({filterIds: ids.filter((id) => id != null)});
                }
            } else {
                if (filterState.length > 0) {
                    debouncedUpdateFilter({filterIds: []});
                }
            }
        },
        [fixtureFilters, filterGroups, filterState]
    );

    const onSeasonFilterSelect = (items: SelectOption[]) => {
        const selectedSeasonIDs = items
            .filter(season => season.id !== null)
            .map(season => season.id.toString())

        debouncedUpdateFilter({seasonIds: selectedSeasonIDs});
    }

    const onTeamFilterSelect = (items: SelectOption[]) => {
        const selectedTeamIDs = items
            .filter(team => team.id !== null)
            .map(team => team.id.toString())

        debouncedUpdateFilter({teamIds: selectedTeamIDs});
    }

    const onPlayerFilterSelect = (items: SelectOption[]) => {
        const selectedPlayerIDs = items
            .filter(player => player.id !== null)
            .map(player => player.id.toString())

        debouncedUpdateFilter({playerIds: selectedPlayerIDs});
    }

    return (
        <section className={'flex vertical'} data-testid={'top-content-container'}>
            <section className={'flex horizontal filter-options'}>
                <section className={'results-type-section'}>
                    <Select testId={'results-type'}
                            label={`${t(FINDER.RESULTS_TYPE)}`}
                            menuItems={resultsOptions}
                            isExternalUpdated={resultTypeUpdated}
                            defaultOption={resultsOptions.find((resultsOption) => resultsOption.id == resultsType)}
                            onSelectItem={(items) => {
                                if (items.length > 0) {
                                    setResultTypeUpdated(false);
                                    mixpanel.track(`Hub-Portal: Search results type selected.`, {resultsType: items[0]});
                                    updateFilter({
                                        resultsType: items[0].id.toString() == ResultType.Matches ? ResultType.Matches : ResultType.Events,
                                        video: false,
                                        events: false
                                    });
                                }
                            }}
                    />
                </section>
                <section className={'flex vertical date-range'}>
                    <Typography className={'date-label'} variant={"label-1"}>{t(FINDER.DATE)}</Typography>
                    <DateRangePicker
                        onChange={(newDateRange) => {
                            mixpanel.track(`Hub-Portal: Search date range selected.`, {dateRange: newDateRange});
                            updateFilter({dateRange: compressDateRangeSeconds(newDateRange)});
                        }}
                        value={dateRange}
                        presets={datePresets}
                        placeholder={'All'}
                        isClearButtonVisible={true}
                        //TODO add these once DateRangePicker in the DS has been
                        // fixed (https://catapult-catalyst.atlassian.net/browse/HP-339)
                        // max={maxMinDatePicker.maxDate}
                        // min={maxMinDatePicker.minDate}
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.COMPETITION)}`}
                            menuItems={competitionOptions}
                            defaultOption={competitionId ? competitionOptions.find((option) => option.id == competitionId) : competitionOptions[0]}
                            isExternalUpdated
                            disabled={competitionOptions.length == 0}
                            onSelectItem={(items) => {
                                let item = ""
                                let itemText = "All deselected"
                                if (items.length > 0) {
                                    let itemStr = items[0].id.toString();
                                    item = itemStr
                                    itemText = itemStr
                                }
                                mixpanel.track(`Hub-Portal: Search competition selected.`, {competition: itemText});
                                updateFilter({competitionId: item});

                            }}
                            isSearchable
                            withDebounce
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.SEASON)}`}
                            menuItems={seasonOptions}
                            onSelectItem={onSeasonFilterSelect}
                            disabled={!seasons || seasons?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.TEAM)}`}
                            menuItems={teamOptions}
                            onSelectItem={onTeamFilterSelect}
                            disabled={!teams || teams?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.PLAYER)}`}
                            menuItems={playerOptions}
                            onSelectItem={onPlayerFilterSelect}
                            disabled={!players || players?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'filter-checkboxes'}>
                    {resultsType === ResultType.Matches ? (
                            <Checkbox label={`${t(FINDER.VIDEO)}`} checked={videoChecked} onChange={() => {
                                mixpanel.track(`Hub-Portal: Search has video toggled.`, {hasVideo: !videoChecked});
                                updateFilter({video: !videoChecked, events: false});
                            }}/>)
                        : null
                    }
                    {
                        //TODO hiding it until it is needed
                    }
                    {/*<Checkbox label={`${t(FINDER.INCLUDE_FUTURE_FIXTURES)}`} checked={includeFutureFixtures} onChange={() => {*/}
                    {/*    mixpanel.track(`Hub-Portal: Future Fixtures toggled.`, {includeFutureFixtures: !includeFutureFixtures});*/}
                    {/*    updateFilter({includeFutureFixtures: !includeFutureFixtures});*/}
                    {/*}}/>*/}
                    {resultsType == ResultType.Matches &&
                        <Checkbox label={`${t(FINDER.EVENTS)}`} checked={eventsChecked}
                                  onChange={() => {
                                      mixpanel.track(`Hub-Portal: Search has events toggled.`, {hasEvents: !eventsChecked});
                                      updateFilter({events: !eventsChecked, video: false});
                                  }}/>}
                </section>
                <section className={'perfect-match'}>
                    <Checkbox
                        label={`${t(FINDER.USE_PERFECT_MATCH)}`}
                        checked={true}
                        disabled
                        onChange={() => {
                            mixpanel.track(`Hub-Portal: Search perfect match toggled.`, {perfectMatch: !perfectMatchChecked});
                            updateFilter({perfectMatch: !perfectMatchChecked});
                        }}/>
                </section>
            </section>
            <section className={'flex horizontal pill-section'}>
                <Select
                    label={'Search Filters'}
                    testId={'categoryFilter'}
                    annotation={t(FINDER.SEARCH_FILTER_ANNOTATION)}
                    placeholder={'Select Something'}
                    groupedMenuItems={filterGroups}
                    onSelectItem={onFilterSelect}
                    disabled={filterGroups.length === 0}
                    isNestedGroups
                    isMultiselect
                    isSearchable
                    useOuterSearch
                    deselectAllOnClear={false}
                    isExternalUpdated={isExternalUpdated}
                    resetGroupOnClear
                    remainOpenOnClear
                    withDebounce
                />
                <FilterChipList data={filterChips} filterData={fixtureFilters} onRemove={onFilterRemove}/>
            </section>
        </section>
    )
};

const generateFixtureFilterFromIds = (id: string, groups: GroupedSelectOptions[]): FixtureFilter | null => {
    const group = groups.find(group => group.menuItems.some(item => item.id === id));

    if (group) {
        const filterType = FilterTypeFromGroupName(group.groupName)
        if (!(filterType === FixtureFilterType.CompetitionID && id === 'any')) {
            return {filterType, id: id, name: group.menuItems.find(item => item.id == id)?.value || ''} as FixtureFilter
        }
    }

    return null;
}

const generateFixtureFilterFromSelect = (option: SelectOption, groups: GroupedSelectOptions[]): FixtureFilter | null => {
    const group = groups.find(group => group.menuItems.some(item => item.id === option.id));

    if (group) {
        const filterType = FilterTypeFromGroupName(group.groupName)
        if (!(filterType === FixtureFilterType.CompetitionID && option.id === 'any')) {
            return {filterType, id: option.id, name: option.value} as FixtureFilter
        }
    }

    return null;
}

const generateFilterChipFromFixtureFilter = (filter: FixtureFilter): FilterChip | null => {
    const groupData = FixtureFilterTypeToGroupData(filter.filterType);

    return {
        id: filter.id,
        label: filter.name ? `${groupData.name}: ${filter.name}` : undefined,
        icon: groupData.icon,
        isEventFilter: false
    };
}
