import {FINDER, GENERAL, HUB, NAV, SEARCH} from "../../../services/i18n/i18n-constants";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import {
    Button,
    Checkbox,
    DateRangePicker,
    Select,
    SelectOption,
    Typography,
    useAlerts,
    useDebounce, usePositioner
} from "@catapultsports/referee-react";
import {useMixPanel} from "../../../providers/Mixpanel";
import {compressDateRangeSeconds, useDatePresets} from "../../../utils/dateUtils";
import useCompetitions from "../../../api/MatchTracker/competitions/useCompetitions";
import useTeams from "../../../api/MatchTracker/teams/useTeams";
import usePlayers from "../../../api/MatchTracker/players/usePlayers";
import {ResultType} from "../../../api/MatchTracker/enum/Common";
import {defineQueryFilters, SearchLocationTypes, usePortalNavigation} from "../../../utils/routerUtils";
import {useLocation, useSearch} from "@tanstack/react-location";
import {ISeason} from "../../../api/MatchTracker/__types__/Common";
import useSeasons from "../../../api/MatchTracker/seasons/useSeasons";
import {useUpdateFilter} from "../../../state/stateUtil";
import {IconFilterSmall} from "@catapultsports/referee-react/icons";
import {SearchFilter, TabOption} from "../../../components/searchFilter/searchFilter";
import {categories, filterOptions} from "../../../components/searchFilter/__mocks__/data";
import * as _ from 'lodash';

export const SearchControls: React.FC<any> = () => {
    //imported hooks
    const {navigate} = usePortalNavigation();
    const {t} = useTranslation();
    const mixpanel = useMixPanel();
    const {filters} = useSearch<SearchLocationTypes>();
    const {showError} = useAlerts();
    const datePresets = useDatePresets();

    const location = useLocation()
    const updateFilter = useUpdateFilter({location: location, navigate: navigate});
    const debouncedUpdateFilter = useDebounce((value: any) => {
        updateFilter(value);
    }, 500);
    const [resultTypeUpdated, setResultTypeUpdated] = useState<boolean>(false);

    const resultsType = useMemo(() => {
        return filters?.resultsType || ResultType.Matches
    }, [filters?.resultsType]);

    const {state: {seasons, seasonsLoading, seasonsPageSize}} =
        useSeasons(undefined, undefined, undefined, undefined, () => showError(t(HUB.SEASONS_ERROR)));

    const [competitionId, videoChecked, eventsChecked, perfectMatchChecked, dateRange, seasonIds, teamIds, playerIds, includeFutureFixtures] = defineQueryFilters(filters, datePresets)

    const resultsOptions = useMemo(() => {
        setResultTypeUpdated(true);
        if (resultsType == ResultType.Matches) {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: true},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: false}
            ]
        } else {
            return [
                {id: ResultType.Matches, value: t(NAV.MATCHES), isSelected: false},
                {id: ResultType.Events, value: t(FINDER.EVENTS), isSelected: true}
            ]
        }
    }, [resultsType]);

    // TODO: this should be paged once infinite scroll is available in select component
    const {state: {competitions}} =
        useCompetitions(undefined, undefined, () => showError(t(HUB.COMPETITIONS_ERROR)));

    const {state: {teams}} = useTeams(
        competitionId ? [competitionId] : [],
        seasonIds ? seasonIds : [],
        undefined,
        undefined,
        () => showError(t(HUB.TEAMS_ERROR)));

    const {state: {players, playersLoading}} = usePlayers(
        teamIds || [],
        undefined,
        undefined,
        () => showError(t(HUB.PLAYERS_ERROR)));

    let convertCurrentPreviousSeasonValues = useCallback((item: ISeason) => {
        let valueToReturn = item.endYear.toString()

        let start = item.startYear
        let end = item.endYear
        if ((start !== null && end !== null))
            if (start !== end) {
                valueToReturn = `${start}/${end}`
            }
        if (item.isCurrentSeason) {
            valueToReturn = t(FINDER.CURRENT_SEASON)
        }
        if (item.isPreviousSeason) {
            valueToReturn = t(FINDER.PREVIOUS_SEASON)
        }
        return valueToReturn
    }, [t])

    const seasonOptions = useMemo(() => {
        if (seasons && seasons.length > 0) {
            const options = [
                ...seasons.map((item) => ({
                    id: item.base.id,
                    value: convertCurrentPreviousSeasonValues(item),
                    startYear: item.startYear.toString(),
                    endYear: item.endYear.toString(),
                    isCurrentSeason: item.isCurrentSeason,
                    isPreviousSeason: item.isPreviousSeason
                }))
            ];
            const seasonIds = filters?.seasonIds
            return options.map((option) => seasonIds?.includes(option.id) ? {...option, isSelected: true} : option);
        }

        return [];
    }, [seasons, filters?.seasonIds, convertCurrentPreviousSeasonValues]);

    const teamOptions = useMemo(() => {
        if (teams && teams.length > 0) {
            const options = [
                ...teams.map((item) => ({id: item.base.id, value: item.name}))
            ];
            const teamIds = filters?.teamIds
            return options.map((option) => teamIds?.includes(option.id) ? {...option, isSelected: true} : option);
        }

        return [];
    }, [teams, filters?.teamIds]);

    const playerOptions = useMemo(() => {
        if (!playersLoading) {
            if (players && players.length > 0) {
                const options = [
                    ...players.map((item) => ({id: item.base.id, value: item.firstName + ' ' + item.lastName}))
                ];
                const playerIds = filters?.playerIds
                const validPlayerIds = playerIds?.filter(x => options.some(o => o.id !== x));
                updateFilter({playerIds: validPlayerIds ?? []});
                return options.map((option) => playerIds?.includes(option.id) ? {...option, isSelected: true} : option);
            }

            updateFilter({playerIds: []});
        }
        return [];
    }, [players, filters?.playerIds, filters?.teamIds]);

    const competitionOptions = useMemo(() => {
        if (competitions && competitions.length > 0) {
            const options = [
                {id: '', value: t(GENERAL.ANY)},
                ...competitions.map((item) => ({id: item.base.id, value: item.name}))
            ];
            return options.map((option) => option.id === competitionId ? {...option, isSelected: true} : option);
        }

        return [];
    }, [competitions, competitionId]);

    const onSeasonFilterSelect = (items: SelectOption[]) => {
        const selectedSeasonIDs = items
            .filter(season => season.id !== null)
            .map(season => season.id.toString())

        debouncedUpdateFilter({seasonIds: selectedSeasonIDs});
    }

    const onTeamFilterSelect = (items: SelectOption[]) => {
        const selectedTeamIDs = items
            .filter(team => team.id !== null)
            .map(team => team.id.toString())

        debouncedUpdateFilter({teamIds: selectedTeamIDs});
    }

    const onPlayerFilterSelect = (items: SelectOption[]) => {
        const selectedPlayerIDs = items
            .filter(player => player.id !== null)
            .map(player => player.id.toString())

        debouncedUpdateFilter({playerIds: selectedPlayerIDs});
    }

    const { getPopperProps, getReferenceProps } = usePositioner({
        placement: 'bottom-start',
    });
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = useCallback(() => setShowFilter(!showFilter), [showFilter, setShowFilter]);
    const onSearch = useCallback((value: string | undefined) => console.log(`Search value: ${value}`), []);
    const onCategory = useCallback((value: string | undefined) => {
        if (value) {
            if (selectedCategories.includes(value)) {
                setSelectedCategories(_.without(selectedCategories, value));
            } else {
                setSelectedCategories([...selectedCategories, value]);
            }
        }
    }, [selectedCategories, setSelectedCategories]);

    return (
        <section className={'flex vertical'} data-testid={'top-content-container'}>
            <section className={'flex horizontal filter-options'}>
                <section className={'results-type-section'}>
                    <Select testId={'results-type'} label={`${t(FINDER.RESULTS_TYPE)}`}
                            menuItems={resultsOptions}
                            isExternalUpdated={resultTypeUpdated}
                            defaultOption={resultsOptions.find((resultsOption) => resultsOption.id == resultsType)}
                            onSelectItem={(items) => {
                                if (items.length > 0) {
                                    setResultTypeUpdated(false);
                                    mixpanel.track(`Hub-Portal: Search results type selected.`, {resultsType: items[0]});
                                    updateFilter({
                                        resultsType: items[0].id.toString() == ResultType.Matches ? ResultType.Matches : ResultType.Events,
                                        video: false,
                                        events: false
                                    });
                                }
                            }}
                    />
                </section>
                <section className={'flex vertical date-range'}>
                    <Typography className={'date-label'} variant={"label-1"}>{t(FINDER.DATE)}</Typography>
                    <DateRangePicker
                        onChange={(newDateRange) => {
                            mixpanel.track(`Hub-Portal: Search date range selected.`, {dateRange: newDateRange});
                            updateFilter({dateRange: compressDateRangeSeconds(newDateRange)});
                        }}
                        value={dateRange}
                        presets={datePresets}
                        placeholder={'All'}
                        isClearButtonVisible={true}
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.COMPETITION)}`}
                            menuItems={competitionOptions}
                            defaultOption={competitionId ? competitionOptions.find((option) => option.id == competitionId) : competitionOptions[0]}
                            isExternalUpdated
                            disabled={competitionOptions.length == 0}
                            onSelectItem={(items) => {
                                let item = ""
                                let itemText = "All deselected"
                                if (items.length > 0) {
                                    let itemStr = items[0].id.toString();
                                    item = itemStr
                                    itemText = itemStr
                                }
                                mixpanel.track(`Hub-Portal: Search competition selected.`, {competition: itemText});
                                updateFilter({competitionId: item});

                            }}
                            isSearchable
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.SEASON)}`}
                            menuItems={seasonOptions}
                            onSelectItem={onSeasonFilterSelect}
                            disabled={!seasons || seasons?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.TEAM)}`}
                            menuItems={teamOptions}
                            onSelectItem={onTeamFilterSelect}
                            disabled={!teams || teams?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'competition-type-section'}>
                    <Select label={`${t(FINDER.PLAYER)}`}
                            menuItems={playerOptions}
                            onSelectItem={onPlayerFilterSelect}
                            disabled={!players || players?.length == 0}
                            isMultiselect
                            isSearchable
                            isExternalUpdated
                            isClearButtonVisible
                            maxDisplayValues={2}
                            withDebounce
                    />
                </section>
                <section className={'filter-checkboxes'}>
                    {resultsType === ResultType.Matches && (
                        <div>
                            <Checkbox
                                label={`${t(FINDER.VIDEO)}`}
                                checked={videoChecked}
                                onChange={() => {
                                    mixpanel.track(`Hub-Portal: Search has video toggled.`, {hasVideo: !videoChecked});
                                    updateFilter({video: !videoChecked, events: false});
                                }}
                            />
                            <Checkbox
                                label={`${t(FINDER.EVENTS)}`}
                                checked={eventsChecked}
                                onChange={() => {
                                    mixpanel.track(`Hub-Portal: Search has events toggled.`, {hasEvents: !eventsChecked});
                                    updateFilter({events: !eventsChecked, video: false});
                                }}
                            />
                        </div>
                    )}
                </section>
                <section className={'perfect-match'}>
                    <Checkbox
                        label={`${t(FINDER.USE_PERFECT_MATCH)}`}
                        checked={true}
                        disabled
                        onChange={() => {
                            mixpanel.track(`Hub-Portal: Search perfect match toggled.`, {perfectMatch: !perfectMatchChecked});
                            updateFilter({perfectMatch: !perfectMatchChecked});
                        }}/>
                </section>
            </section>
            <section className={'filter-dropdown-options'}>
                <Button variant={"outline"} onClick={toggleFilter} {...getReferenceProps()}>{t(SEARCH.FILTERS)} <IconFilterSmall/></Button>
                {showFilter &&
                    <SearchFilter
                        perfectMatch={true}
                        onPerfectMatchChanged={() => console.log('perfect match')}
                        onCategorySelected={onCategory}
                        categories={categories}
                        selectedCategoryIds={selectedCategories}
                        onSearch={onSearch}
                        onClose={toggleFilter}
                        selectedTab={TabOption.Basic}
                        onTabSelected={(selected: TabOption) => console.log(`Tab option selected: ${selected}`)}
                        popperProps={getPopperProps}
                        filterOptions={filterOptions}
                        hasMoreFilters={true}
                        onLoadNextPage={() => console.log('Load next filter page!!')}/>}
            </section>
        </section>
    )
};
