import React, {useEffect} from "react";
import MatchTrackerApolloProvider from "../../providers/ApolloProviders/MatchTrackerApolloProvider";
import './nextGenFinder.sass'
import {SearchControls} from "./subPages/searchControls";
import {ResultsSection} from "./subPages/resultsSection";
import {useSessionStore} from "../../state/SessionState";
import {shallow} from "zustand/shallow";

export enum ResultType {
    Matches = 'Matches',
    Events = 'Events'
}

export const NextGenFinder: React.FC<any> = () => {
    const setSelectedVideoAngle = useSessionStore(state => state.setSelectedFinderVideoAngle, shallow);

    useEffect(() => {
        setSelectedVideoAngle(undefined);
    }, []);
    return (
        <MatchTrackerApolloProvider>
            <article className={'screen'}>
                <section className={'flex vertical finder-screen'}>
                    <SearchControls/>
                    <ResultsSection/>
                </section>
            </article>
        </MatchTrackerApolloProvider>
    )
};
