import React, {useEffect, useMemo} from "react";
import {FilterItemType, PlaylistType, SortBy} from "../../../api/Hub/enum/Enum";
import {getPathBackToPage, usePortalNavigation} from "../../../utils/routerUtils";
import {Routes} from "../../../enums/Routes";
import {useLocation} from "@tanstack/react-location";
import {CollectionList} from "../../../components/collectionList/collectionList";
import {Spinner, Typography} from "@catapultsports/referee-react";
import {HUB} from "../../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import './overview.sass';
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import useMeasure from "react-use-measure";
import {IMetaDataGroupEdges} from "../../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {MetadataGroupSideFilterMenu} from "../../../components/metadataGroupSideFilterMenu/MetadataGroupSideFilterMenu";
import {CollectionFiltersHide, CollectionTopLevelFilters} from "./filterConstants";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";

const MEASURE_DEBOUNCE = 100;
const ITEM_HEIGHT = 170;

export const Overview: React.FC<any> = () => {
    const location = useLocation();
    const {navigate} = usePortalNavigation();
    const { t } = useTranslation();
    const setMetadataFilters = useSessionStore(state => state.setCollectionsMetadataIds, shallow);
    const setSortBy = useSessionStore(state => state.setCollectionsSortBy, shallow);
    const [collectionRef, collectionBounds] = useMeasure({ debounce: MEASURE_DEBOUNCE });
    const [presentationRef, presentationBounds] = useMeasure({ debounce: MEASURE_DEBOUNCE });

    useEffect(() => {
        setMetadataFilters([]);
        setSortBy(SortBy.CreationTimestamp);
    }, []);

    const getPlaylistCount = (containerHeight: number) => {
        let count = 0;
        if (collectionBounds.height) {
            count = Math.floor(containerHeight / ITEM_HEIGHT);
        }
        return count;
    };

    const collectionCount = useMemo(() => {
        return getPlaylistCount(collectionBounds.height);
    }, [collectionBounds.height]);
    const presentationCount = useMemo(() => {
        return getPlaylistCount(presentationBounds.height);
    }, [collectionBounds.height]);

    return (
        <div className={'overview-wrapper'}>
            <div className={'filter-wrapper'}>
                <Typography variant={'heading-2'}>{t(HUB.OVERVIEW)}</Typography>
                <MetadataGroupSideFilterMenu
                    key={'overviewFilters'}
                    itemType={FilterItemType.Playlist}
                    onFilterChange={setMetadataFilters}
                    groupFilter={(edge) => !edge.hidden && !edge.hideInFilterView && !CollectionFiltersHide.includes(edge.name.toLowerCase())}
                    groupTransform={(edge: IMetaDataGroupEdges) => {
                        if (CollectionTopLevelFilters.includes(edge.name.toLowerCase())) {
                            return {...edge, type: 1}
                        }

                        return edge
                    }}
                    isFixed
                />
            </div>
            <section className={'flex vertical collections-overview'}>
                <section className={'flex horizontal title-section'}>
                    <Typography className={'playlist-title'} variant={'heading-2'}>{t(HUB.COLLECTIONS)}</Typography>
                    <FreeTextSearch/>
                </section>
                <section ref={collectionRef} className={'collection-wrapper'}>
                    { collectionCount ?
                        <CollectionList playlistType={PlaylistType.Collection}
                             pageSize={collectionCount}
                             onCollectionSelect={collection => navigate({
                                 to: getPathBackToPage(
                                     `${Routes.HUB_PAGE}/${Routes.COLLECTIONS}/${collection.id}`,
                                     location.current.pathname,
                                     location.current.pathname != '/'),
                                 replace: true
                             })}
                             blockLoading={true}
                             displayLimit={collectionCount}
                        /> :
                        <Spinner/>
                    }
                </section>
                <Typography className={'playlist-title no-display'} variant={'heading-2'}>{t(HUB.PRESENTATIONS)}</Typography>
                <section ref={presentationRef} className={'collection-wrapper no-display'}>
                    { presentationCount ?
                        <CollectionList playlistType={PlaylistType.Presentation}
                             pageSize={presentationCount}
                             onCollectionSelect={collection => navigate({
                                 to: getPathBackToPage(
                                     `${Routes.HUB_PAGE}/${Routes.PRESENTATIONS}/${collection.id}`,
                                     location.current.pathname,
                                     location.current.pathname != '/'),
                                 replace: true
                             })}
                             blockLoading={true}
                             displayLimit={presentationCount}
                        /> :
                        <Spinner/>
                    }
                </section>
            </section>
        </div>
    )
};
