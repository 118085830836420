import React, {useEffect} from "react";
import './clips.sass';
import {Outlet} from "@tanstack/react-location";
import {ClipsList} from "../../../components/clipList/clipList";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";

export const Clips: React.FC<any> = () => {
    const setVideoAngle = useSessionStore(state => state.setSelectedHubVideoAngle, shallow);

    useEffect(() => {
        setVideoAngle(undefined);
    }, []);
    return (
        <article className={'clips'}>
            <nav className={'clips-nav'}>
                <FreeTextSearch />
            </nav>
            <section className='clips-container'>
                <ClipsList />
                <Outlet />
            </section>
        </article>
    )
};
