import React, {useCallback, useMemo, useState} from "react";
import {COLLECTIONS, GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {Input, useAlerts} from "@catapultsports/referee-react";
import {useTranslation} from "react-i18next";
import "./collection.sass"
import useUpdatePlaylist from "../../api/Hub/playlist/updatePlaylist/useUpdatePlaylist";
import {useMixPanel} from "../../providers/Mixpanel";
import {HubDialogComponent} from "../hubDialog/hubDialog";
interface CollectionRenameProps {
    collectionId: string;
    name: string;
    displayRenameModal?: boolean;
    setDisplayRenameModal?: (displayRenameModal: boolean) => void;

}

export const CollectionRename: React.FC<CollectionRenameProps> = (props) => {
    const {collectionId, name, displayRenameModal, setDisplayRenameModal} = props;

    const { t } = useTranslation();
    const { showError, showInfo } = useAlerts();
    const mixpanel = useMixPanel();

    const [collectionName, setCollectionName] = useState(name)

    const hasCollectionNameChanged = useMemo(() => {
        return collectionName?.trim() !== name;

    }, [collectionName, name])

    const handleOnNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectionName(event.target.value);
    }, [])

    const errorAlert = useCallback(() => {
        showError(t(HUB.UPDATE_COLLECTION_ERROR));
    }, [showError, t])


    const onSuccess = useCallback(() => {
        mixpanel.track(`Collection Renamed`, { collectionID: collectionId, collectionName: collectionName });
        let text = `${t(COLLECTIONS.COLLECTION_UPDATED_SINGLE)}`
        showInfo(text);
        setDisplayRenameModal && setDisplayRenameModal(!displayRenameModal)
    }, [mixpanel, collectionId, collectionName, t, showInfo, setDisplayRenameModal, displayRenameModal])

    const {updatePlaylist} = useUpdatePlaylist(errorAlert, onSuccess);

    const handleOnSave = useCallback(() => {
        let updatePlaylistObject = [{
            id: collectionId,
            name: collectionName,
        }]
        updatePlaylist(updatePlaylistObject)
    }, [collectionId, collectionName, updatePlaylist])

    return (
        <HubDialogComponent
            dialogTheme={"dark"}
            dialogSize={'small'}
            dialogHeader={t(COLLECTIONS.RENAME_COLLECTION).toString()}
            isModalOpen={displayRenameModal}
            setIsModalOpen={setDisplayRenameModal}
            modalContent={
                <Input
                    as={"input"}
                    size={"small"} variant={"primary"}
                    fullWidth={true}
                    value={collectionName}
                    onInput={handleOnNameChange}
                />
            }
            displayDialogFooterButtons={true}
            hideCloseButton={true}
            footerPrimaryButtonText={t(GENERAL.SAVE).toString()}
            footerPrimaryButtonAction={handleOnSave}
            footerPrimaryButtonDisabled={!hasCollectionNameChanged}
            dialogId={"rename-collection-collection-list-modal"}
            allowDialogClosure={false}
        />
    )
}