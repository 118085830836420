import {QualifierType, QualifierValueType} from "../__mocks__/data";
import React, {useState} from "react";
import {Button, Input, Select, Switch, Tooltip, Typography} from "@catapultsports/referee-react";
import {OperatorMenu, OperatorValue} from "./operatorMenu";
import {IconRemove} from "@catapultsports/referee-react/icons";


export interface FilterMenuRowProps {
    filterOption: QualifierType,
    operatorUpdated: (operator: any) => void
}

export const FilterMenuRow: React.FC<FilterMenuRowProps> = (props) => {
    const {filterOption, operatorUpdated} = props;
    const [selectedOperator, setSelectedOperator] = useState(OperatorValue.Equal);

    function renderFilterValue(filterOption: QualifierType) {
        switch(filterOption.valueType) {
            case QualifierValueType.Text: {
                const selectOptions = filterOption.values?.map((value) => {return {
                    id: value,
                    value: value,
                    isSelected: false
                }});
                return <Select isSearchable isMultiselect menuItems={selectOptions} size="small" onSelectItem={(items) => {
                    console.log(items + ' selected')
                }}/>;
            }
            case QualifierValueType.Boolean: {
                return <Switch size="small" onChange={() => {
                    console.log(filterOption.name + ' selected')
                }}/>;
            }
            case QualifierValueType.Numeric: {
                if(filterOption.formatType == 'fixed'){
                    return <Input fullWidth size={"small"} defaultValue={filterOption.values} />
                } else {
                    return <div className={'rangeValue'}>
                        <Input size={"small"} defaultValue={filterOption.limits?.min} />
                        <Typography variant={'body-1'}>Slider Goes Here</Typography>
                        <Input size={"small"} defaultValue={filterOption.limits?.max} />
                    </div>
                }
            }
            default: {
                return <Typography className={'filter-name'}
                                   variant={'body-2'}>Unknown Type</Typography>
            }
        }
    }


    return <div className={"filter-option"}
                data-testid={`filter-option:${filterOption.id}`}>
        <Tooltip text={filterOption.name}>
            <Typography numberOfLines={1} className={"filter-name"}
                        variant={"body-2"}>{filterOption.name}</Typography>
        </Tooltip>
        <OperatorMenu
            operatorUpdated={(op) => {
                setSelectedOperator(op);
                operatorUpdated(op);
            }}
        />
        <div className={"filter-value"}>
            {renderFilterValue(filterOption)}
        </div>
        <div className={"options-area"}>
            {/*
                                        TODO Add this back when needed
                                        <Button variant={'text'} size={'small'}>
                                            <IconMoreSmall/>
                                        </Button>
                                        */}
            <Button variant={"text"} size={"small"}>
                <IconRemove/>
            </Button>
        </div>
    </div>;
}