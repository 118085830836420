// generated file, please do not update manually see project README for details

export const NAV = {
		HUB: "nav.hub",
		GAME_FINDER: "nav.game-finder",
		LICENSING: "nav.licensing",
		NOTIFICATIONS: "nav.notifications",
		HELP: "nav.help",
		FAVOURITES: "nav.favourites",
		PIT_WALL: "nav.pit-wall",
		MATCHES: "nav.matches",
		NEXT_GEN: "nav.next-gen",
}
export const ACCOUNT = {
		MY_ACCOUNT: "account.my-account",
		LOG_OUT: "account.log-out",
}
export const LOGIN = {
		EMAIL: "login.email",
		PASSWORD: "login.password",
		REMEMBER_ME: "login.remember-me",
		FORGOT_PASSWORD: "login.forgot-password",
		SIGN_IN: "login.sign-in",
		PROFILE: "login.profile",
		INVALID_EMAIL: "login.invalid-email",
		REQUIRED_FIELD: "login.required-field",
		INCORRECT_LOGIN: "login.incorrect-login",
		SERVER_ERROR: "login.server-error",
		LOGOUT: "login.logout",
		EXPIRED_REDIRECT: "login.expired-redirect",
}
export const HUB = {
		OVERVIEW: "hub.overview",
		COLLECTIONS: "hub.collections",
		PRESENTATIONS: "hub.presentations",
		CLIPS: "hub.clips",
		CLIP: "hub.clip",
		ADJUST: "hub.adjust",
		SCREEN_MESSAGE: "hub.screen-message",
		CLIP_NOT_FOUND: "hub.clip-not-found",
		PLAYLIST_NOT_FOUND: "hub.playlist-not-found",
		UNKNOWN_CLIP: "hub.unknown-clip",
		COMMENTS: "hub.comments",
		LABELS: "hub.labels",
		MORE: "hub.more",
		LESS: "hub.less",
		NO_RESULTS: "hub.no-results",
		COLLECTION_UNAVAILABLE: "hub.collection-unavailable",
		COLLECTION_COMMENTS_UNAVAILABLE: "hub.collection-comments-unavailable",
		CLIP_COMMENTS_UNAVAILABLE: "hub.clip-comments-unavailable",
		NOT_FOUND: "hub.not-found",
		NO_LABELS: "hub.no-labels",
		COMMENTS_ERROR: "hub.comments-error",
		LABELS_ERROR: "hub.labels-error",
		FILTERS_ERROR: "hub.filters-error",
		CLIP_ERROR: "hub.clip-error",
		COMPETITIONS_ERROR: "hub.competitions-error",
		COMPETITION_SEASONS_ERROR: "hub.competition-seasons-error",
		EVENT_FIXTURE_MEDIA_ERROR: "hub.event-fixture-media-error",
		PHASE_TYPE_ERROR: "hub.phase-type-error",
		PLAYERS_ERROR: "hub.players-error",
		PROVIDERS_ERROR: "hub.providers-error",
		QUALIFIER_TYPES_ERROR: "hub.qualifier-types-error",
		TEAMS_ERROR: "hub.teams-error",
		VENUES_ERROR: "hub.venues-error",
		FIXTURES_ERROR: "hub.fixtures-error",
		COMMENT_PLACEHOLDER: "hub.comment-placeholder",
		ADD_COMMENT: "hub.add-comment",
		REPLY_TO: "hub.reply-to",
		COMMENT_DEPTH_EXCEEDED: "hub.comment-depth-exceeded",
		COMMENT_POST_FAILURE: "hub.comment-post-failure",
		COMMENT_EMPTY_CONTENTS: "hub.comment-empty-contents",
		SEASONS_ERROR: "hub.seasons-error",
		SEARCH_PLACEHOLDER: "hub.search-placeholder",
		SORT_BY: "hub.sort-by",
		SELECT_STREAM: "hub.select-stream",
		METADATA_GROUP_NOT_FOUND: "hub.metadata-group-not-found",
		POST_COMMENT: "hub.post-comment",
		POST_REPLY: "hub.post-reply",
		DELETE_PLAYLIST_ERROR: "hub.delete-playlist-error",
		EDIT_LABELS: "hub.edit-labels",
		ADD_LABEL: "hub.add-label",
		CLIP_NAME: "hub.clip-name",
		LABELS_UPDATED: "hub.labels-updated",
		LABELS_UPDATE_ERROR: "hub.labels-update-error",
		EDIT_LABELS_TOOLTIP: "hub.edit-labels-tooltip",
		COLLECTION_NAME: "hub.collection-name",
		ENABLED: "hub.enabled",
		ADD_GROUP: "hub.add-group",
		ADD: "hub.add",
		LABEL_GROUP_NAME: "hub.label-group-name",
		GROUP_NAME: "hub.group-name",
		DELETE_CLIP_FROM_PLAYLIST_SUCCESS_MULTIPLE: "hub.delete-clip-from-playlist-success-multiple",
		DELETE_CLIP_FROM_PLAYLIST_SUCCESS_SINGLE: "hub.delete-clip-from-playlist-success-single",
		DELETE_CLIP_FROM_PLAYLIST_ERROR_SINGLE: "hub.delete-clip-from-playlist-error-single",
		DELETE_CLIP_FROM_PLAYLIST_ERROR_MULTIPLE: "hub.delete-clip-from-playlist-error-multiple",
		UPDATE_COLLECTION_ERROR: "hub.update-collection-error",
		OPEN_IN_HUB: "hub.open-in-hub",
		OPENING_IN_HUB: "hub.opening-in-hub",
		NO_LABEL_GROUPS: "hub.no-label-groups",
}
export const COLLECTIONS = {
		CREATE_COLLECTION_MODAL_SUB_HEADER: "collections.create-collection-modal-sub-header",
		CREATE_COLLECTION_MODAL_INPUT_LABEL: "collections.create-collection-modal-input-label",
		COLLECTION_CREATED: "collections.collection-created",
		NAME_COLLECTION: "collections.name-collection",
		COLLECTION_DELETED: "collections.collection-deleted",
		COLLECTION_DELETE_MODAL_HEADER: "collections.collection-delete-modal-header",
		COLLECTION_DELETE_MODAL_TEXT: "collections.collection-delete-modal-text",
		VIEW_NEW_COLLECTION: "collections.view-new-collection",
		EDIT_COLLECTION: "collections.edit-collection",
		SAVE_CHANGES: "collections.save-changes",
		COLLECTION_UPDATED_SINGLE: "collections.collection-updated-single",
		COLLECTION_UPDATED_MULTIPLE: "collections.collection-updated-multiple",
		EDIT_COLLECTION_LABELS: "collections.edit-collection-labels",
		DELETE_CLIPS_FROM_COLLECTION: "collections.delete-clips-from-collection",
		SELECT_CLIPS: "collections.select-clips",
		DELETE_CLIPS_MODAL_HEADER: "collections.delete-clips-modal-header",
		DELETE_CLIPS_MODAL_TEXT: "collections.delete-clips-modal-text",
		EDIT_COLLECTION_NAME: "collections.edit-collection-name",
		RENAME_COLLECTION: "collections.rename-collection",
		PRE_ROLL: "collections.pre-roll",
		POST_ROLL: "collections.post-roll",
		PRE_POST_ROLL_TOO_HIGH: "collections.pre-post-roll-too-high",
		PRE_AND_POST_ROLL_UPDATED: "collections.pre-and-post-roll-updated",
		PRE_AND_POST_ROLL_UPDATED_MULTIPLE: "collections.pre-and-post-roll-updated-multiple",
		PRE_AND_POST_ROLL_FAILED: "collections.pre-and-post-roll-failed",
		PRE_AND_POST_ROLL_FAILED_MULTIPLE: "collections.pre-and-post-roll-failed-multiple",
		UPDATE_PRE_AND_POST_ROLL: "collections.update-pre-and-post-roll",
		SELECT_CLIPS_TO_UPDATE_PRE_AND_POST_ROLL: "collections.select-clips-to-update-pre-and-post-roll",
		ADJUST_PRE_AND_POST_ROLL: "collections.adjust-pre-and-post-roll",
		ADD_TIME_TO_CLIPS: "collections.add-time-to-clips",
		DEFAULT_VIDEO_ANGLE_UPDATED: "collections.default-video-angle-updated",
		DEFAULT_VIDEO_ANGLE_UPDATE_ERROR: "collections.default-video-angle-update-error",
		VIDEO_ANGLES_AVAILABLE: "collections.video-angles-available",
		UPDATE_VIDEO_ANGLE_HEADER: "collections.update-video-angle-header",
		VIDEO_ANGLE_NOT_AVAILABLE_FOR_CLIP: "collections.video-angle-not-available-for-clip",
		VIDEO_ANGLE_BUTTON_TOOLTIP: "collections.video-angle-button-tooltip",
		CLIPS_LENGTH_LIMIT: "collections.clips-length-limit",
		CREATE_NEW: "collections.create-new",
		ADD_TO_EXISTING: "collections.add-to-existing",
		SELECT_A_COLLECTION: "collections.select-a-collection",
		SEARCH_FOR_COLLECTIONS: "collections.search-for-collections",
		SELECT_ONE_OR_MORE_COLLECTION: "collections.select-one-or-more-collection",
		VIEW_UPDATED_COLLECTION: "collections.view-updated-collection",
		CLOSE_EDIT_COLLECTION: "collections.close-edit-collection",
}
export const LICENSING = {
		HOME: "licensing.home",
		MY_LICENSES: "licensing.my-licenses",
		ADMIN: "licensing.admin",
		ALL_ADMINS: "licensing.all-admins",
		ALL_LICENSES: "licensing.all-licenses",
		CUSTOMER_SUBSCRIPTIONS: "licensing.customer-subscriptions",
		SCREEN_MESSAGE: "licensing.screen-message",
		LICENSING: "licensing.licensing",
		SUPPORT_MESSAGE: "licensing.support-message",
		INSTALLERS: "licensing.installers",
		LICENSES_PENDING: "licensing.licenses-pending",
		RELEASE_NOTES: "licensing.release-notes",
		DOWNLOAD_EXE: "licensing.download-exe",
		DOWNLOAD_DMG: "licensing.download-dmg",
		REVIEW_LICENSES: "licensing.review-licenses",
		PENDING_LICENSES_1: "licensing.pending-licenses-1",
		PENDING_LICENSES_2: "licensing.pending-licenses-2",
		LICENSE_USAGE: "licensing.license-usage",
		SUBSCRIPTION: "licensing.subscription",
		PRODUCT: "licensing.product",
		PURCHASED: "licensing.purchased",
		USED: "licensing.used",
		AVAILABLE: "licensing.available",
		SUBSCRIPTION_DATE: "licensing.subscription-date",
		AUTO_APPROVE: "licensing.auto-approve",
		ADMIN_LIST: "licensing.admin-list",
		CREATE_NEW_CUSTOMER: "licensing.create-new-customer",
		CREATE_NEW_USER: "licensing.create-new-user",
		SELECT_CUSTOMER: "licensing.select-customer",
		REQUESTED: "licensing.requested",
		SUPPORT_LINK: "licensing.support-link",
		HUB_API: "licensing.hub-Api",
		CUSTOMER_API: "licensing.customer-API",
		RESET_LINK: "licensing.reset-Link",
		HTTP_LINK: "licensing.http-Link",
		ALL_USERS: "licensing.all-users",
		EDIT_SUBSCRIPTIONS: "licensing.edit-subscriptions",
		USER: "licensing.user",
		COMPUTER: "licensing.computer",
		RELEASE: "licensing.release",
		LAST_LOGIN: "licensing.last-login",
		EXPIRY: "licensing.expiry",
		REMAINING: "licensing.remaining",
		STATUS: "licensing.status",
		TOOLTIP_APPROVE: "licensing.tooltip-approve",
		TOOLTIP_EDIT: "licensing.tooltip-edit",
		TOOLTIP_REJECT: "licensing.tooltip-reject",
		TOOLTIP_DOWNLOAD: "licensing.tooltip-download",
		TOOLTIP_PIN: "licensing.tooltip-pin",
		TOOLTIP_DELETE: "licensing.tooltip-delete",
		TOOLTIP_REVOKE: "licensing.tooltip-revoke",
		FILTER: "licensing.filter",
		REQUEST_NEW_PASSWORD: "licensing.request-new-password",
		FULL_NAME: "licensing.full-name",
		ACCOUNT_STATUS: "licensing.account-status",
		PASSWORD_RESET: "licensing.password-reset",
		DELETE_USER: "licensing.delete-user",
		LICENSE_STATUS_ERROR: "licensing.license-status-error",
		INSTALLERS_ERROR: "licensing.installers-error",
		MEMBERSHIPS_ERROR: "licensing.memberships-error",
		USER_FOR_CUSTOMER_ERROR: "licensing.user-for-customer-error",
		AUTO_APPROVE_STATUS: "licensing.auto-approve-status",
		AUTO_APPROVE_ENABLE: "licensing.auto-approve-enable",
		AUTO_APPROVE_DISABLE: "licensing.auto-approve-disable",
		LICENSE_ACTION: "licensing.license-action",
		AUTO_APPROVE_UPDATED: "licensing.auto-approve-updated",
		AUTO_APPROVE_UPDATE_ERROR: "licensing.auto-approve-update-error",
		CUSTOMER_SELECTION: "licensing.customer-selection",
		CUSTOMER_SELECTION_TEXT: "licensing.customer-selection-text",
		CUSTOMER_SELECTION_SEARCH_TEXT: "licensing.customer-selection-search-text",
		CUSTOMER_SELECTION_SELECT_ACCOUNT: "licensing.customer-selection-select-account",
		ADMIN_TOOLS: "licensing.admin-tools",
		ADMIN_LIST_SEARCH_TEXT: "licensing.admin-list-search-text",
		LICENSE_USAGE_MODAL: "licensing.license-usage-modal",
}
export const LICENSING_ROLES = {
		ADMIN_ROLE: "licensing-roles.admin-role",
}
export const FINDER = {
		SCREEN_MESSAGE: "finder.screen-message",
		VIEW_EVENTS: "finder.view-events",
		VIEW_GAMES: "finder.view-games",
		SEND_TO_MATCHTRACKER: "finder.send-to-matchtracker",
		HOME_TEAM: "finder.home-team",
		HOME: "finder.home",
		AWAY: "finder.away",
		AWAY_TEAM: "finder.away-team",
		RELEVANCE: "finder.relevance",
		LEAGUE: "finder.league",
		MATCH_DATE: "finder.match-date",
		RESULTS_TYPE: "finder.results-type",
		GAMES: "finder.games",
		EVENTS: "finder.events",
		DATE: "finder.date",
		COMPETITION: "finder.competition",
		VIDEO: "finder.video",
		USE_PERFECT_MATCH: "finder.use-perfect-match",
		CREATE_A_COLLECTION: "finder.create-a-collection",
		EVENT: "finder.event",
		TIMESTAMP: "finder.timestamp",
		TIME: "finder.time",
		GAME: "finder.game",
		META_DATA: "finder.meta-data",
		EVENT_NO_VIDEO: "finder.event-no-video",
		NO_GAME_DATA: "finder.no-game-data",
		QUALIFIERS: "finder.qualifiers",
		QUALIFIER: "finder.qualifier",
		TEAM: "finder.team",
		PLAYER: "finder.player",
		PLAYERS: "finder.players",
		SEASON: "finder.season",
		PERIOD: "finder.period",
		VENUE: "finder.venue",
		PROVIDER: "finder.provider",
		COACH: "finder.coach",
		REFEREE: "finder.referee",
		MATCHTRACKER_LIMIT_25: "finder.matchtracker-limit-25",
		SENDING_TO_MATCHTRACKER: "finder.sending-to-matchtracker",
		FIXTURES_UNAVAILABLE: "finder.fixtures-unavailable",
		EVENTS_UNAVAILABLE: "finder.events-unavailable",
		CURRENT_SEASON: "finder.current-season",
		PREVIOUS_SEASON: "finder.previous-season",
		CREATE_COLLECTION_ERROR: "finder.create-collection-error",
		SEARCH_FILTER_ANNOTATION: "finder.search-filter-annotation",
}
export const GENERAL = {
		LOADING_TEXT: "general.loading-text",
		SUBMIT: "general.submit",
		CONTENT_COMING_SOON: "general.content-coming-soon",
		UNEXPECTED_ERROR: "general.unexpected-error",
		ERROR_FALLBACK: "general.error-fallback",
		ANY: "general.any",
		REPLY: "general.reply",
		DOWNLOAD: "general.download",
		CONTINUE: "general.continue",
		ENABLED: "general.enabled",
		DISABLED: "general.disabled",
		POST: "general.post",
		FILTERS: "general.filters",
		CLEAR_ALL_FILTERS: "general.clear-all-filters",
		CREATE: "general.create",
		SAVE_CHANGES: "general.save-changes",
		DELETE: "general.delete",
		CANCEL: "general.cancel",
		SAVE: "general.save",
		OF: "general.of",
		NO_RESULTS: "general.no-results",
		EDIT: "general.edit",
		SELECT_ALL: "general.select-all",
		DESELECT_ALL: "general.deselect-all",
		RENAME: "general.rename",
		PRE_ROLL: "general.pre-roll",
		POST_ROLL: "general.post-roll",
		UPDATE: "general.update",
}
export const LOST = {
		MESSAGE: "lost.message",
		HOME_BUTTON: "lost.home-button",
}
export const GRID_FOOTER = {
		SELECTED: "grid-footer.selected",
}
export const VIDEO_SCRUBBER = {
		SCRUB_BACK_15S: "video-scrubber.scrub-back-15s",
		SCRUB_BACK_5S: "video-scrubber.scrub-back-5s",
		SCRUB_BACK_1S: "video-scrubber.scrub-back-1s",
		SCRUB_BACK_1F: "video-scrubber.scrub-back-1f",
		SCRUB_FORWARD_1F: "video-scrubber.scrub-forward-1f",
		SCRUB_FORWARD_1S: "video-scrubber.scrub-forward-1s",
		SCRUB_FORWARD_5S: "video-scrubber.scrub-forward-5s",
		SCRUB_FORWARD_15S: "video-scrubber.scrub-forward-15s",
}
export const F1_VIDEO = {
		UNMUTE: "f1-video.unmute",
		MUTE: "f1-video.mute",
		FULLSCREEN: "f1-video.fullscreen",
		INFO_HIDE: "f1-video.info-hide",
		INFO_SHOW: "f1-video.info-show",
		EJECT: "f1-video.eject",
		PLACEHOLDER: "f1-video.placeholder",
		BACK_10S: "f1-video.back-10s",
		FORWARD_10S: "f1-video.forward-10s",
		PLAY: "f1-video.play",
		PAUSE: "f1-video.pause",
		LABEL_PLAYBACK: "f1-video.label-playback",
		LABEL_LOCATION: "f1-video.label-location",
		SESSIONS_ERROR: "f1-video.sessions-error",
		SESSION_CLIPS_ERROR: "f1-video.session-clips-error",
}
export const DATE_PRESETS = {
		ALL: "date-presets.all",
		TODAY: "date-presets.today",
		LAST_SEVEN_DAYS: "date-presets.last-seven-days",
		LAST_THIRTY_DAYS: "date-presets.last-thirty-days",
		LAST_YEAR: "date-presets.last-year",
		LATEST_THIRTY_DAYS: "date-presets.latest-thirty-days",
		LAST_TWO_YEARS: "date-presets.last-two-years",
		LAST_FIVE_YEARS: "date-presets.last-five-years",
}
export const EVENTNAMES = {
		UNKNOWN: "eventNames.UNKNOWN",
		PASS: "eventNames.PASS",
		OFFSIDE_PASS: "eventNames.OFFSIDE_PASS",
		TAKE_ON: "eventNames.TAKE_ON",
		FOUL: "eventNames.FOUL",
		OUT: "eventNames.OUT",
		CORNER: "eventNames.CORNER",
		TACKLE: "eventNames.TACKLE",
		INTERCEPTION: "eventNames.INTERCEPTION",
		SAVE: "eventNames.SAVE",
		GOALKEEPER_CLAIM: "eventNames.GOALKEEPER_CLAIM",
		CLEARANCE: "eventNames.CLEARANCE",
		MISS: "eventNames.MISS",
		HIT_WOODWORK: "eventNames.HIT_WOODWORK",
		SHOT_SAVED: "eventNames.SHOT_SAVED",
		GOAL: "eventNames.GOAL",
		CARD: "eventNames.CARD",
		PLAYER_OFF: "eventNames.PLAYER_OFF",
		PLAYER_ON: "eventNames.PLAYER_ON",
		PLAYER_RETIRED: "eventNames.PLAYER_RETIRED",
		PLAYER_RETURNS: "eventNames.PLAYER_RETURNS",
		CONDITION_CHANGE: "eventNames.CONDITION_CHANGE",
		START_DELAY: "eventNames.START_DELAY",
		END_DELAY: "eventNames.END_DELAY",
		PERIOD_END: "eventNames.PERIOD_END",
		PERIOD_START: "eventNames.PERIOD_START",
		TEAM_LINEUP: "eventNames.TEAM_LINEUP",
		PLAYER_CHANGED_POSITION: "eventNames.PLAYER_CHANGED_POSITION",
		PLAYER_CHANGED_NUMBER: "eventNames.PLAYER_CHANGED_NUMBER",
		COLLECTION_END: "eventNames.COLLECTION_END",
		TEMP_GOAL: "eventNames.TEMP_GOAL",
		TEMP_GOAL_ATTEMPT: "eventNames.TEMP_GOAL_ATTEMPT",
		FORMATION_CHANGE: "eventNames.FORMATION_CHANGE",
		GOALKEEPER_PUNCH: "eventNames.GOALKEEPER_PUNCH",
		GOOD_SKILL: "eventNames.GOOD_SKILL",
		DELETED_EVENT: "eventNames.DELETED_EVENT",
		AERIAL_DUEL: "eventNames.AERIAL_DUEL",
		CHALLENGE: "eventNames.CHALLENGE",
		RED_CARD_RECINDED: "eventNames.RED_CARD_RECINDED",
		BALL_RECOVERY: "eventNames.BALL_RECOVERY",
		DISPOSSESSED: "eventNames.DISPOSSESSED",
		PLAYER_ERROR: "eventNames.PLAYER_ERROR",
		KEEPER_PICK_UP: "eventNames.KEEPER_PICK_UP",
		CROSS_NOT_CLAIMED: "eventNames.CROSS_NOT_CLAIMED",
		SMOTHER: "eventNames.SMOTHER",
		OFFSIDE_PROVOKED: "eventNames.OFFSIDE_PROVOKED",
		SHIELD_BALL: "eventNames.SHIELD_BALL",
		FOUL_THROW: "eventNames.FOUL_THROW",
		PENALTY_FACED: "eventNames.PENALTY_FACED",
		KEEPER_SWEEPER: "eventNames.KEEPER_SWEEPER",
		CHANCE_MISSED: "eventNames.CHANCE_MISSED",
		BALL_TOUCH: "eventNames.BALL_TOUCH",
		PLACEHOLDER: "eventNames.PLACEHOLDER",
		TEMP_SAVE: "eventNames.TEMP_SAVE",
		RESUME: "eventNames.RESUME",
		CONTENTIOUS_REFEREE_DECISION: "eventNames.CONTENTIOUS_REFEREE_DECISION",
		POSSESSION_DATA: "eventNames.POSSESSION_DATA",
		_50_50: "eventNames.50_50",
		REFEREE_DROP_BALL: "eventNames.REFEREE_DROP_BALL",
		FAIL_TO_BLOCK: "eventNames.FAIL_TO_BLOCK",
		INJURY_TIME_ANNOUNCEMENT: "eventNames.INJURY_TIME_ANNOUNCEMENT",
		COACH_SETUP: "eventNames.COACH_SETUP",
		CAUGHT_OFFSIDE: "eventNames.CAUGHT_OFFSIDE",
		OTHER_BALL_TOUCH: "eventNames.OTHER_BALL_TOUCH",
		BLOCKED_PASS: "eventNames.BLOCKED_PASS",
		DELAYED_START: "eventNames.DELAYED_START",
		EARLY_FINISH: "eventNames.EARLY_FINISH",
		PLAYER_OFF_PITCH: "eventNames.PLAYER_OFF_PITCH",
		COVERAGE_INTERRUPTION: "eventNames.COVERAGE_INTERRUPTION",
		DROP_OF_BALL: "eventNames.DROP_OF_BALL",
		OBSTACLE: "eventNames.OBSTACLE",
		ATTEMPTED_TACKLE: "eventNames.ATTEMPTED_TACKLE",
		DELETED_AFTER_REVIEW: "eventNames.DELETED_AFTER_REVIEW",
		PLAYER: "eventNames.PLAYER",
		BOOKMARK: "eventNames.BOOKMARK",
		USER: "eventNames.USER",
}
export const NOTES = {
		DEFAULT_NOTE: "notes.DEFAULT_NOTE",
		ADD_NOTE: "notes.ADD_NOTE",
		EDIT_NOTE: "notes.EDIT_NOTE",
		REMOVE_NOTE: "notes.REMOVE_NOTE",
		SAVE_CHANGES: "notes.SAVE_CHANGES",
		UPDATE_NOTES: "notes.update-notes",
		UPDATE_NOTES_EXTRA_TEXT: "notes.update-notes-extra-text",
		UPDATE_NOTES_ERROR: "notes.update-notes-error",
}
export const JOYRIDE = {
		NEXT: "joyride.next",
		GOT_IT: "joyride.got-it",
		BACK: "joyride.back",
		DONE: "joyride.done",
		LEARN_MORE: "joyride.learn-more",
		INITIAL_TITLE: "joyride.initial-title",
		INITIAL_CONTENTS: "joyride.initial-contents",
		APPZI_TITLE: "joyride.appzi-title",
		APPZI_CONTENTS: "joyride.appzi-contents",
}
export const SEARCH = {
		BASIC_FILTERS: "search.basic-filters",
		ADVANCED_MODE: "search.advanced-mode",
		PRESETS: "search.presets",
		PERFECT_MATCH: "search.perfect-match",
		FILTERS: "search.filters",
}
