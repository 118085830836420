import React, {useCallback, useMemo} from "react";
import './clip.sass';
import {Checkbox, Typography} from "@catapultsports/referee-react";
import {IconComments, IconChevronRight, IconMore} from "@catapultsports/referee-react/icons";
import {IAsset, IColour} from "../../api/Hub/common/Common";

interface ClipProps {
    id: string,
    name: string,
    commentCount: number
    colour: IColour
    isActive: boolean
    assets?: IAsset[]
    onClick?: (assets: IAsset[]) => void,
    onArrowClick?: (id: string, name: string) => void
    selectedTagIds?: string[]
    displayCheckbox?: boolean
    setSelectedTagIds?: (ids: string[]) => void
}

export const Clip: React.FC<ClipProps> = (props) => {
    const {
        id,
        name,
        colour,
        commentCount,
        isActive,
        assets,
        onClick,
        onArrowClick,
        selectedTagIds,
        displayCheckbox= false,
        setSelectedTagIds,
    } = props;

    const handleCheckboxChange = useCallback(() => {
        if(selectedTagIds && setSelectedTagIds){
            if (selectedTagIds.includes(id))
                setSelectedTagIds(selectedTagIds.filter(x => x != id));
            else
                setSelectedTagIds([...selectedTagIds, id]);
        }
    }, [selectedTagIds, setSelectedTagIds, id])

    const isCheckboxChecked = useMemo(() => {
        if(selectedTagIds)
            return selectedTagIds.includes(id);
    }, [id, selectedTagIds])

    const arrowClickFunction = useCallback((id: string, name: string ) => {
        onArrowClick ? onArrowClick(id, name) : true
        onClick? onClick(assets ? assets : []) : () => {}
    }, [assets, onArrowClick, onClick])

    return (
        <section
            className={['clip-container clip', isActive ? 'active' : ''].join(' ')}
            key={id}
            style={{ borderLeft: `3px solid rgb(${colour.red / 255}, ${colour.green / 255}, ${colour.blue / 255})` }}
            data-testid={`clip-${id}`}
        >
            {displayCheckbox?
                <Checkbox
                    testId={`clip-checkbox-${id}`}
                    Id={`clip-checkbox-${id}`}
                    label={""}
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                />
                : null
            }
            <Typography
                onClick={() => onClick ? onClick(assets ? assets : []) : () => {}}
                testId={`clip-${id}-name`}
                numberOfLines={1} className={'clip-name'}>
                {name}
            </Typography>
            <section className={'clip-options'}>
                {commentCount > 0 && (
                    <span className={'comment-count'}>
                        <IconComments />
                        <Typography>{commentCount}</Typography>
                    </span>
                )}
                <div data-testid={`clip-${id}-arrow`} className={'option'} onClick={() => arrowClickFunction(id, name)}><IconChevronRight /></div>
            </section>
        </section>
    )
};
