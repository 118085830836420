import {ApolloError, useMutation} from "@apollo/client";
import {handleErrorRaised} from "../../../../utils/apolloUtils";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {client as hubClient} from "../../../Hub/client";
import {DELETE_PLAYLIST} from "./deletePlaylist.gql";
import {HUB} from "../../../../services/i18n/i18n-constants";
import {clearSpecificCacheForAGivenClient} from "../../../commonApolloClientFunctions";

const useDeletePlaylist = ( onError?: (error: ApolloError, errorMessage: string) => void, onSuccess?: () => void) => {
    const { t } = useTranslation();

    const [deletePlaylists, { error }] = useMutation(DELETE_PLAYLIST, {
        onCompleted: () => {
            clearSpecificCacheForAGivenClient(hubClient, "playlists")
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    useEffect(() => {
        let errorMessage = t(HUB.DELETE_PLAYLIST_ERROR)
        handleErrorRaised(error, DELETE_PLAYLIST, errorMessage, onError);
    }, [error, onError, t]);

    return {
        deletePlaylists: (playlistIDs: {id: string} [],) => deletePlaylists({
            variables: {
                "input": playlistIDs
            },
        })
    };
};

export default useDeletePlaylist;