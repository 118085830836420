import React, {MutableRefObject, useCallback, useRef, useState} from "react";
import {ICollectionTile} from "../../api/Hub/collections/__types__/Collections";
import './collection.sass'
import {
    Avatar,
    Button, noop,
    SelectOptions,
    Typography, useAlerts,
    usePositioner
} from "@catapultsports/referee-react";
import {
    IconCamera,
    IconComments, IconDeleteSmall,
    IconMoreSmall, IconPencilSmall,
    IconTag,
    IconUsers
} from "@catapultsports/referee-react/icons";
import {CleanEmail, CleanupTypeName} from "../../utils/stringUtils";
import {FallbackImageUrl} from "../../utils/imageUtils";
import {GENERAL, HUB} from "../../services/i18n/i18n-constants";
import {useTranslation} from "react-i18next";
import {CollectionRename} from "./collectionRename";
import {CollectionDelete} from "./collectionDelete";
import {useMixPanel} from "../../providers/Mixpanel";
import {IconHubClient} from "../../../icons";


interface CollectionItem extends ICollectionTile {
    onClick: (item: ICollectionTile) => void
}

export const Collection: React.FC<CollectionItem> = (props) => {
    const {id, name, description, thumbnail, tags, users, metadata, commentCount, creationTimestamp, modifiedTimestamp, onClick, playlistType} = props
    const { t } = useTranslation();
    const {showInfo} = useAlerts();
    const mixpanel = useMixPanel();
    const [displaySelectOptions, setDisplaySelectOptions] = useState(false)
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
    const [displayRenameModal, setDisplayRenameModal] = useState(false)

    const handleOnOptionsClick = useCallback((event: { stopPropagation: () => void; }) => {
        event.stopPropagation()
        setDisplaySelectOptions(!displaySelectOptions)
    }, [displaySelectOptions])

    const buttonRef = useRef(null) as unknown as MutableRefObject<HTMLElement>
    const {getReferenceProps, getPopperProps} = usePositioner({
        placement: 'bottom-end',
    });

    let handleOptionDeleteClick = useCallback(() => {
        setDisplayDeleteModal(true)
        setDisplaySelectOptions(false)
    }, [])

    let handleOptionRenameClick = useCallback(() => {
        setDisplayRenameModal(true)
        setDisplaySelectOptions(false)
    }, [])

    let handleOptionSendToHubClick = useCallback(() => {
        setDisplaySelectOptions(false);
        mixpanel.track(`Hub-Portal: Deeplink Collection to Hub Client.`, { collectionId: id, playlistType: playlistType });
        showInfo(t(HUB.OPENING_IN_HUB));
        window.location.assign(`hub://playlist?id=${id}&type=${playlistType}`);
    }, [])


    let options = [
        {name: t(GENERAL.RENAME), startIcon: <IconPencilSmall/>, clickFunction: handleOptionRenameClick},
        {name: t(HUB.OPEN_IN_HUB), startIcon: <IconHubClient/>, clickFunction: handleOptionSendToHubClick},
        {name: t(GENERAL.DELETE), startIcon: <IconDeleteSmall/>, clickFunction: handleOptionDeleteClick},
    ]

    const handleListClick = useCallback(() => {
        if(displaySelectOptions || displayDeleteModal || displayRenameModal){
            noop()
        }else{
            onClick(props as ICollectionTile)
        }
    }, [displaySelectOptions, displayDeleteModal, displayRenameModal, onClick, props])



    return (
        <li data-testid={'collection-wrapper'} key={id} onClick={handleListClick} >
            <img src={thumbnail.file?.s3Link || FallbackImageUrl()} alt={`Thumbnail for ${name}`} onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                if (currentTarget.src !== FallbackImageUrl()) {
                    currentTarget.src = FallbackImageUrl()
                }
            }} />
            <div className={'dates'}>
                <Typography variant={'heading-4'}>{name}</Typography>
                <Typography variant={'body-1'}>
                    Modified: {new Date(modifiedTimestamp).toDateString()} &nbsp;&nbsp;&nbsp;&nbsp; Created: {new Date(creationTimestamp).toDateString()}
                </Typography>
                <div className={'meta'}>
                        <Typography variant={'caption-1'} className={"icons-align"}><IconCamera />
                            {tags.length}
                        </Typography>
                        <Typography variant={'caption-1'} className={"icons-align"}><IconComments /> {commentCount}</Typography>
                </div>
            </div>
            <div className={'collection-meta'}>
                <div className={"tags-users-div"}>
                    <span><IconTag /></span>
                    <Typography variant={'caption-1'} className={"common-tags-users"} numberOfLines={3}>
                        {metadata.filter(tag => tag.value.trim() !== '').map(tag => CleanupTypeName(tag.value)).join(', ')}
                    </Typography>
                </div>
                <div className={"tags-users-div"}>
                    <span><IconUsers /></span>
                    <div className={"tags-style"}>
                        {users.map((user, index) => (
                            <Avatar testId={`avatar-${user.id}-${index}`} key={`${user.id}-${index}`} name={CleanEmail(user.email)} size={'small'} shape={'square'} />
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div ref={buttonRef}>
                    <Button testId={"collection-more-options"} id={"collection-more-options"} variant="text" className={`collection-options ${displaySelectOptions? "options-visible": ""}`} onClick={handleOnOptionsClick}  {...getReferenceProps()}>
                        <IconMoreSmall/>
                    </Button>
                </div>
                {displaySelectOptions? (
                    <div data-testid={"collection-options-menu"}>
                        <SelectOptions
                            popperProps={getPopperProps}
                            parentRef={buttonRef}
                            setIsVisible={setDisplaySelectOptions}
                            isVisible={displaySelectOptions}
                            onSelectItem={noop}
                            menuItems={options?.map((element, index) => ({
                                id: index,
                                key: index,
                                isSelected: false,
                                value: element.name,
                                startIcon: element.startIcon,
                                onClick: element.clickFunction
                            }))}
                        />
                    </div>
                ): null}
                {displayDeleteModal? (
                    <CollectionDelete
                        collectionId={id}
                        displayDeleteModal={displayDeleteModal}
                        setDisplayDeleteModal={setDisplayDeleteModal}
                        setDisplaySelectOptions={setDisplaySelectOptions}
                    />
                ): null}
                {displayRenameModal? (
                    <CollectionRename
                        collectionId={id}
                        name={name}
                        displayRenameModal={displayRenameModal}
                        setDisplayRenameModal={setDisplayRenameModal}
                    />
                ) : null}
            </div>
        </li>
    )
}