import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {ICollectionTile} from "../../../api/Hub/collections/__types__/Collections";
import {FilterItemType, PlaylistType, SortBy} from "../../../api/Hub/enum/Enum";
import {Button, SelectOptions, Typography, usePositioner} from "@catapultsports/referee-react";
import {IconFilter} from "@catapultsports/referee-react/icons";
import {useMixPanel} from "../../../providers/Mixpanel";
import {MixPanelEvents} from "../../../enums/MixPanelEvents";
import {useSessionStore} from "../../../state/SessionState";
import {shallow} from "zustand/shallow";
import './collections.sass';
import {useTranslation} from "react-i18next";
import {HUB} from "../../../services/i18n/i18n-constants";
import {MetadataGroupSideFilterMenu} from "../../../components/metadataGroupSideFilterMenu/MetadataGroupSideFilterMenu";
import {CollectionList} from "../../../components/collectionList/collectionList";
import {IMetaDataGroupEdges} from "../../../api/Hub/metadataGroups/__types__/MetadataGroup";
import {CollectionFiltersHide, CollectionTopLevelFilters} from "./filterConstants";
import {FreeTextSearch} from "../../../components/freeTextSearch/freeTextSearch";

interface CollectionScreenProps {
    playlistType: PlaylistType
    headerMargin?: string
    pageSize?: number
    allowSort?: boolean
    onCollectionSelect?: (collection: ICollectionTile) => void,
    blockLoading?: boolean,
    displayLimit?: number
}

export const CollectionsScreen: React.FC<CollectionScreenProps> = (props) => {
    const dropdownRef = useRef(null) as unknown as MutableRefObject<HTMLElement>;
    const { getPopperProps, getReferenceProps } = usePositioner({ placement: 'bottom-end' });
    const [ sortOpen, setSortOpen ] = useState(false);
    const setMetadataFilters = useSessionStore(state => state.setCollectionsMetadataIds, shallow);
    const sortBy = useSessionStore(state => state.collections.sortBy, shallow);
    const setSortBy = useSessionStore(state => state.setCollectionsSortBy, shallow);
    const mixpanel = useMixPanel();
    const { t } = useTranslation();

    useEffect(() => {
        setMetadataFilters([]);
        setSortBy(SortBy.CreationTimestamp);
    }, [props.playlistType]);

    return (
        <section className={'collections-screen'}>
            <nav className={'collections-nav'} style={{ padding: props.headerMargin ?? '16px 16px 16px 0', minHeight: '70px' }}>
                <Typography variant={'heading-2'}>{props.playlistType === PlaylistType.Collection ? t(HUB.COLLECTIONS) : t(HUB.PRESENTATIONS)}</Typography>
                <section className={'flex horizontal'}>
                    <FreeTextSearch />
                    {(props.allowSort ?? true) && (
                        <Button className={'sort-button'} variant={'outline'} {...getReferenceProps()} onClick={() => setSortOpen(!sortOpen)}>
                            <IconFilter /> {t(HUB.SORT_BY)}
                        </Button>
                    )}
                </section>
            </nav>
            <section className={'screen-content'}>
                <MetadataGroupSideFilterMenu
                    key={props.playlistType}
                    itemType={FilterItemType.Playlist}
                    onFilterChange={setMetadataFilters}
                    groupFilter={(edge) => !edge.hidden && !edge.hideInFilterView && !CollectionFiltersHide.includes(edge.name.toLowerCase())}
                    groupTransform={(edge: IMetaDataGroupEdges) => {
                        if (CollectionTopLevelFilters.includes(edge.name.toLowerCase())) {
                            return {...edge, type: 1}
                        }

                        return edge
                    }}
                    isFixed
                />
                <CollectionList {...props} />
            </section>
            <SelectOptions
                popperProps={getPopperProps}
                parentRef={dropdownRef}
                setIsVisible={setSortOpen}
                isVisible={sortOpen}
                onSelectItem={selectedValue => {
                    if ("value" in selectedValue && selectedValue.value === 'Modified Date') {
                        setSortBy(SortBy.ModifiedTimestamp)
                    } else {
                        setSortBy(SortBy.CreationTimestamp)
                    }

                    if ("value" in selectedValue) {
                        mixpanel.track(props.playlistType === PlaylistType.Collection ? MixPanelEvents.COLLECTION_SORT : MixPanelEvents.PRESENTATION_SORT, {sortBy: selectedValue.value})
                    }
                }}
                menuItems={[
                    {
                        id: 0,
                        isSelected: sortBy === SortBy.ModifiedTimestamp,
                        value: 'Modified Date',
                    },
                    {
                        id: 1,
                        isSelected: sortBy === SortBy.CreationTimestamp,
                        value: 'Created Date'
                    }
                ]}
            />
        </section>
    )
}